import { useLayoutEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
	COMMAND_PRIORITY_CRITICAL,
	KEY_ENTER_COMMAND,
	LexicalCommand,
	createCommand,
} from 'lexical';

export const SAVE_COMMAND: LexicalCommand<KeyboardEvent> = createCommand('SAVE_COMMAND');

const CommandsPlugin = () => {
	const [editor] = useLexicalComposerContext();

	useLayoutEffect(() => {
		/** Prevents a new line from being inserted when pressing CMD + Enter to save */
		editor.registerCommand(
			KEY_ENTER_COMMAND,
			() => {
				return true;
			},
			COMMAND_PRIORITY_CRITICAL
		);
	}, [editor]);

	return null;
};

export default CommandsPlugin;
