import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { API_URL } from '../../config';
import axios from 'axios';
import { StoreState, store } from '../store';

export type Page = {
	_id: string;
	searchText: string;
	title: string;
	tags: string[];
	workspace?: string;
	folderPath?: string;
	createdAt: string;
};

type PagesSliceState = {
	isPagesLoading: boolean;
	pages: Page[];
};

export const PLACEHOLDER_PAGE_ID = 'placeholder-page';

const initialState: PagesSliceState = {
	isPagesLoading: false,
	pages: [],
};

export const enrichPageData = (data: Partial<Page>) => {
	return {
		_id: '',
		searchText: '',
		title: '',
		tags: [],
		folderPath: '',
		createdAt: '',
		...data,
	};
};

export const createPage = createAsyncThunk(
	'entries/save-search',
	async (inputValues: Partial<Page>, store) => {
		const { selectedWorkspaceId } = (store.getState() as StoreState).workspaces;

		if (selectedWorkspaceId) {
			inputValues.workspace = selectedWorkspaceId;
		}

		console.log(inputValues);

		const result = await axios.post(`${API_URL}/pages`, inputValues);

		return result.data;
	}
);

export const getPages = createAsyncThunk('pages/get', async () => {
	const { selectedWorkspaceId } = (store.getState() as StoreState).workspaces;

	let params: Record<string, any> = {};

	if (selectedWorkspaceId) {
		params.workspace = selectedWorkspaceId;
	}
	const result = await axios.get(`${API_URL}/pages`, { params });

	return result.data;
});

export const deletePages = createAsyncThunk('pages/delete', async (ids: string[]) => {
	if (ids.length === 1) {
		await axios.delete(`${API_URL}/pages/${ids[0]}`);
	} else {
		await axios.delete(`${API_URL}/pages/bulk?ids=${ids.join(',')}`);
	}

	return { ids };
});

export const editPage = createAsyncThunk('pages/edit', async (payload: Partial<Page>) => {
	await axios.put(`${API_URL}/pages/${payload._id}`, payload);

	return payload;
});

export const changePagePath = createAsyncThunk(
	'pages/change-path',
	async (payload: { pageId: string; updatedPath: string }, thunk) => {
		thunk.dispatch(updatePagePathAction(payload));

		await axios.put(`${API_URL}/pages/${payload.pageId}`, {
			folderPath: payload.updatedPath,
		});	
	}
);

export const pagesSlice = createSlice({
	name: 'pages',
	initialState,
	reducers: {
		updatePagePathAction: (state, action) => {
			let pageIndex = state.pages.findIndex((page) => page._id === action.payload.pageId);
			console.log(state.pages[pageIndex]);
			if (pageIndex > -1) {
				console.log(action.payload);
				state.pages[pageIndex].folderPath = action.payload.updatedPath;
				state.pages = current(state.pages);
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPages.pending, (state) => {
			state.isPagesLoading = true;
		});

		builder.addCase(getPages.fulfilled, (state, action) => {
			state.isPagesLoading = false;
			state.pages = action.payload;
		});

		builder.addCase(createPage.fulfilled, (state, action) => {
			state.pages.unshift(action.payload);
		});

		builder.addCase(deletePages.fulfilled, (state, action) => {
			state.pages = state.pages.filter((page) => !action.payload.ids.includes(page._id));
		});
		
		builder.addCase(editPage.fulfilled, (state, action) => {
			const pageIndex = state.pages.findIndex((page) => page._id === action.payload._id);

			state.pages[pageIndex] = { ...state.pages[pageIndex], ...action.payload };
		});
	},
});

export const { updatePagePathAction } = pagesSlice.actions;
