import { Box, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineFileAdd, AiOutlineFolderAdd } from 'react-icons/ai';

interface Props {
	onAddPageClick: (e: MouseEvent<HTMLElement>) => void;
	onAddFolderClick: (e: MouseEvent<HTMLElement>) => void;
}

const AddButtons: React.FC<Props> = ({ onAddPageClick, onAddFolderClick }) => {
	const { t } = useTranslation();
	return (
		<Box display='flex' width='fit-content' ml='auto'>
			<Tooltip label={t('addPage')}>
				<IconButton
					onClick={onAddPageClick}
					aria-label='add-folder-icon'
					backgroundColor='transparent'
					size='sm'>
					<Icon as={AiOutlineFileAdd} />
				</IconButton>
			</Tooltip>

			<Tooltip label={t('addFolder')}>
				<IconButton
					onClick={onAddFolderClick}
					aria-label='add-folder-icon'
					backgroundColor='transparent'
					size='sm'>
					<Icon as={AiOutlineFolderAdd} />
				</IconButton>
			</Tooltip>
		</Box>
	);
};

export default AddButtons;
