import {
	Box,
	Card,
	CardBody,
	Icon,
	Heading,
	FormControl,
	FormLabel,
	Input,
	Button,
	useToast,
} from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import { FiSettings } from 'react-icons/fi';
import { User, UserSettings } from '../../../redux/slices/current-user';
import { updateUser } from '../../../redux/slices/users';
import { useAppDispatch } from '../../../redux/store';
import { useTranslation } from 'react-i18next';

interface Props {
	user: User;
}

const Settings: React.FC<Props> = ({ user }) => {
	const [inputValues, setInputValues] = useState<Partial<UserSettings>>({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const dispatch = useAppDispatch();
	const toast = useToast();
	const updates = useRef<{ [path: string]: any }>({});
	const { t } = useTranslation();

	const handleSave = async () => {
		setIsSaveLoading(true);
		await dispatch(updateUser({ id: user._id, updates: updates.current }));

		setIsSaveLoading(false);
		toast({
			title: t('userUpdateSuccess'),
			status: 'success',
			duration: 9000,
			position: 'top-right',
			isClosable: true,
		});
		updates.current = {};
	};

	useEffect(() => {
		setInputValues({ ...(user.settings || {}) });
	}, []);

	return (
		<Card>
			<CardBody bgColor='gray.50'>
				<Box mb={4} display='flex' alignItems='center'>
					<Icon mr={3} fontSize='lg' as={FiSettings} />
					<Heading fontSize='lg'>{t('settings')}</Heading>
				</Box>

				<FormControl>
					<FormLabel fontSize={14} mb={2}>
						{t('chatGptTokensLimit')}
					</FormLabel>
					<Input
						size='sm'
						type='number'
						width={100}
						value={Number(inputValues.limits?.chatGptTokens || 0) || ''}
						onChange={(e) => {
							setInputValues({
								...inputValues,
								limits: {
									...(inputValues.limits || {}),
									chatGptTokens: Number(e.target.value),
								},
							});

							updates.current['limits.chatGptTokens'] = Number(e.target.value);
						}}
					/>
				</FormControl>

				<Box width='fit-content' ml='auto'>
					<Button
						isDisabled={!Object.keys(updates.current).length}
						size='sm'
						isLoading={isSaveLoading}
						onClick={handleSave}
						colorScheme='blue'>
						{t('save')}
					</Button>
				</Box>
			</CardBody>
		</Card>
	);
};

export default Settings;
