import { Box, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
	suggestion: Suggestion;
	index: number;
	focusedSuggestionIndex: number;
	onClick: (suggestion: Suggestion) => void;
}

export type Suggestion = {
	value: string | ReactNode;
	/** String value of the suggestion item to allow for it to be searched when the "value" is of type ReactNode */
	searchableValue?: string;
	description?: string;
};

const SuggestionItem: React.FC<Props> = (props) => {
	const { suggestion, onClick, focusedSuggestionIndex, index } = props;
	return (
		<Box
			className={SUGGESTION_ITEM_CLASS}
			sx={{
				cursor: 'pointer',
				'&:hover': {
					// TODO: set propery color
					backgroundColor: 'gray.100'
				},
				// TODO: set propery color

				backgroundColor: focusedSuggestionIndex === index ? 'gray.300' : 'white',
				display: 'flex',
				p: 1,
				alignItems: 'center',
                zIndex: 99999
			}}
			/** onMouseDown is used instead of onClick to trigger action before losing focus on the search bar,
			 * which would cause it to be closed
			 */
			onMouseDown={() => onClick(suggestion)}
			key={index}>
			<Text className={SUGGESTION_ITEM_CLASS} fontSize={11} color='text.primary'>
				{suggestion.value}
			</Text>

			{suggestion.description && (
				<Box width='fit-content' ml='auto'>
					<Text fontSize={11} color='text.secondary' className={SUGGESTION_ITEM_CLASS}>
						{suggestion.description}
					</Text>
				</Box>
			)}
		</Box>
	);
};

/** This class is used to detect when a suggestion element is clicked.
 * It's not used to apply any styles to the elements
 */
export const SUGGESTION_ITEM_CLASS = 'suggestion-item';

export default SuggestionItem;
