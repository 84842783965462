import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { Page, createPage, editPage } from '../../redux/slices/pages';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/store';
import TagsInput from '../Inputs/TagsInput';

interface Props {
	isOpen: boolean;
	selectedPage?: Page | null;
	defaultInputValues?: Partial<Page>;
	onClose: () => void;
}

type Errors = { searchText?: string; tags?: string; title?: string };

const PageModal: React.FC<Props> = ({ isOpen, selectedPage, defaultInputValues, onClose }) => {
	const [inputValues, setInputValues] = useState<Partial<Page>>({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [errors, setErrors] = useState<Errors>({});
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const handleSave = async () => {
		let errors: Errors = {};

		const { searchText, tags } = inputValues;

		if (!searchText && !tags?.length) {
			errors.searchText = t('required') as string;
		}

		setErrors(errors);

		if (Object.keys(errors).length) {
			return;
		}

		setIsSaveLoading(true);

		if (selectedPage) {
			await dispatch(editPage(inputValues));
		} else {
			await dispatch(createPage(inputValues));
		}

		setIsSaveLoading(false);
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			if (selectedPage) {
				setInputValues({ ...selectedPage });
			} else {
				setInputValues({ ...(defaultInputValues || {}) });
			}
			setErrors({});
			setIsSaveLoading(false);
		}
	}, [defaultInputValues, isOpen, selectedPage]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent pb={4}>
				<ModalHeader>{selectedPage ? t('editPage') : t('createPage')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl isInvalid={Boolean(errors.title)}>
						<FormLabel>{t('title')}</FormLabel>
						<Input
							autoFocus
							value={inputValues.title}
							onChange={(e) => setInputValues({ ...inputValues, title: e.target.value })}
							onKeyDown={(e) => e.key === 'Enter' && handleSave()}
						/>
						<FormErrorMessage>{errors.title}</FormErrorMessage>
					</FormControl>

					<FormControl mt={4} isInvalid={Boolean(errors.searchText)}>
						<FormLabel>{t('searchText')}</FormLabel>
						<Input
							value={inputValues.searchText}
							onChange={(e) => setInputValues({ ...inputValues, searchText: e.target.value })}
							onKeyDown={(e) => e.key === 'Enter' && handleSave()}
						/>
						<FormErrorMessage>{errors.searchText}</FormErrorMessage>
					</FormControl>

					<Text mt={4} mb={2}>
						{t('tags')}
					</Text>
					<TagsInput
						value={inputValues.tags || []}
						onChange={(tags) => setInputValues({ ...inputValues, tags })}
					/>

					<Text mt={4}>{t('folderPath')}</Text>
					<Input
						mt={2}
						mb={4}
						isDisabled={Boolean(defaultInputValues?.folderPath)}
						value={inputValues.folderPath}
						onChange={(e) => setInputValues({ ...inputValues, folderPath: e.target.value })}
						onKeyDown={(e) => e.key === 'Enter' && handleSave()}
					/>
				</ModalBody>
				<ModalFooter gap={4}>
					<Button onClick={onClose}>{t('close')}</Button>
					<Button isLoading={isSaveLoading} colorScheme='blue' mr={3} onClick={handleSave}>
						{t('save')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default PageModal;
