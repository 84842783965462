import { Box, Drawer, DrawerContent, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { ReactNode, useEffect, useMemo } from 'react';
import SidebarContent, { useSideBarWidth } from './Nav/SidebarContent/SidebarContent';
import Header, { HEADER_HEIGHT } from './Nav/Header';
import { store, useAppDispatch } from '../redux/store';
import { getUserInfo } from '../redux/slices/current-user';
import { useLocation, useNavigate } from 'react-router';
import { getWorkspaces } from '../redux/slices/workspaces';
import { ADMIN_PAGE_BASE_PATH, LOGIN_PAGE_PATH } from '../App';
import { getSettings } from '../redux/slices/general-settings';

export default function PageContainer({ children }: { children: ReactNode }) {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const sideBarWidth = useSideBarWidth();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const isAdminPage = useMemo(
		() => location.pathname.startsWith(ADMIN_PAGE_BASE_PATH),
		[location.pathname]
	);

	useEffect(() => {
		(async () => {
			const result = await dispatch(getUserInfo());
			if (!result.payload) {
				navigate(LOGIN_PAGE_PATH);
			} else if (isAdminPage && !result.payload.isAdmin) {
				navigate('/');
			} else {
				if (!store.getState().workspaces.workspaces.length) {
					dispatch(getWorkspaces());
				}

				if (!store.getState().generalSettings.settings) {
					dispatch(getSettings());
				}
			}
		})();
	}, []);

	const sidebarContentProps = {
		isAdminPage,
	};

	return (
		<Box overflowX='hidden' minH='100vh' bg={useColorModeValue('gray.100', 'gray.900')}>
			<SidebarContent
				{...sidebarContentProps}
				display={{ base: 'none', lg: 'flex' }}
				onClose={onClose}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement='left'
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}>
				<DrawerContent>
					<SidebarContent {...sidebarContentProps} onClose={onClose} />
				</DrawerContent>
			</Drawer>

			<Header display={{ base: 'flex', lg: 'none' }} onDrawerOpen={onOpen} />
			<Box
				height={{ base: `calc(100vh - ${HEADER_HEIGHT}px)`, lg: '100vh' }}
				overflowY='scroll'
				ml={{ base: 0, lg: sideBarWidth + 'px' }}>
				<Box
					maxWidth={1200}
					mx='auto'
					width={{ base: '100%', lg: `calc(100vw - ${sideBarWidth}px)` }}
					p='4'
					pl='8'>
					{children}
				</Box>
			</Box>
		</Box>
	);
}
