import { useLocation, useSearchParams } from 'react-router-dom';
import { APP_NAME, AUTH_REDIRECT_URL, COGNITO_AUTH_URL, COGNITO_CLIENT_ID } from '../config';
import { Flex, Box, Stack, Button, Heading, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../redux/store';
import { getCredentialsFromCode } from '../redux/slices/current-user';

const LoginPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handleLoginClick = () => {
		window.location.replace(
			`${COGNITO_AUTH_URL}/login?client_id=${COGNITO_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${AUTH_REDIRECT_URL}`
		);
	};

	useEffect(() => {
		(async () => {
			const code = searchParams.get('code');

			if (code) {
				setIsLoading(true);
				const result = await dispatch(getCredentialsFromCode(code));
				console.log(result.payload);
				setIsLoading(false);

				if (result.payload) {
					navigate('/');
				}
			}
		})();
	}, [location]);

	return (
		<Flex
			minH='100vh'
			align='center'
			justify='center'
			bg={useColorModeValue('gray.50', 'gray.800')}>
			<Stack spacing={8} mx={'auto'} maxW={'2xl'} py={12} px={6}>
				<Stack align={'center'}>
					<Heading fontSize='4xl'>Welcome to {APP_NAME}!</Heading>
				</Stack>
				<Stack spacing={4}>
					<Box width='fit-content' mx='auto'>
						<Button
							isLoading={isLoading}
							onClick={handleLoginClick}
							mt={3}
							bg={'blue.400'}
							color={'white'}
							width={300}
							_hover={{
								bg: 'blue.500',
							}}>
							Login
						</Button>
					</Box>
				</Stack>
			</Stack>
		</Flex>
	);
};

export default LoginPage;
