import {
	Box,
	Icon,
	IconButton,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Tooltip,
} from '@chakra-ui/react';
import { CSSProperties, ChangeEvent, useEffect, useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { StoreState, useAppDispatch } from '../../redux/store';
import { isEqual, trim } from 'lodash';
import SuggestionsInput from './SuggestionsInput/SuggestionsInput';
import { isMacOS } from '../../helpers';
import { fetchEntries } from '../../redux/slices/entries';
import { useTranslation } from 'react-i18next';
import { setSearchValue } from '../../redux/slices/entries-search';

interface Props {
	style?: CSSProperties;
	isDisabled?: boolean;
}

const EntriesSearchInput: React.FC<Props> = (props) => {
	const { style, isDisabled } = props;
	const { t } = useTranslation();
	const searchValue = useSelector((state: StoreState) => state.entriesSearch.searchValue);
	const [inputValue, setInputValue] = useState('');
	const isInputFocused = useRef(false);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const tags = useSelector((state: StoreState) => state.entries.tags);
	const dispatch = useAppDispatch();

	const handleSearchSubmit = () => {
		const { text, tags } = parseSearchString(inputValue);

		if (searchValue.text !== text || !isEqual(tags, searchValue.tags)) {
			dispatch(setSearchValue({ text, tags }));
			dispatch(fetchEntries({ text, tags }));
		}
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setInputValue(value);
		// debouncedChangeHandler(value);
	};

	const handleClearClick = () => {
		setInputValue('');
		dispatch(fetchEntries());
	};

	useEffect(() => {
		if (!isInputFocused.current) {
			setInputValue(
				`${searchValue.text}${searchValue.tags.length ? `[${searchValue.tags.join(',')}]` : ''}`
			);
		}
	}, [searchValue]);

	useEffect(() => {
		if (isDisabled) {
			isInputFocused.current = false;
		} else {
			inputRef.current?.focus();
		}
	}, [isDisabled]);

	useEffect(() => {
		const keyDownHandler = (e: KeyboardEvent) => {
			const isMetaKeyPressed = isMacOS() ? e.metaKey : e.ctrlKey;
			if (isMetaKeyPressed && e.code === 'KeyF' && !isInputFocused.current) {
				e.preventDefault();
				inputRef.current?.focus();
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, []);

	return (
		<InputGroup style={style} bgColor='white'>
			<InputLeftElement
				pointerEvents='none'
				children={<Icon as={AiOutlineSearch} color='gray.300' />}
			/>
			<SuggestionsInput
				inputRef={(ref) => (inputRef.current = ref)}
				suggestions={tags.map((tag) => ({
					value: tag,
				}))}
				triggerRegex={/\[(.*?)\]/g}
				onFocus={() => (isInputFocused.current = true)}
				onBlur={() => {
					isInputFocused.current = false;
				}}
				value={inputValue}
				isDisabled={isDisabled}
				onChange={handleChange}
				onEnter={handleSearchSubmit}
				sx={{ paddingLeft: 10, paddingRight: 24 }}
				placeholder='Search entries...'
			/>

			<InputRightElement
				style={{ cursor: 'pointer', width: 'fit-content' }}
				children={
					<Box display='flex' pr={2}>
						<Tooltip label={t('clearSearch')}>
							<IconButton
								visibility={inputValue ? 'visible' : 'hidden'}
								size='sm'
								aria-label='clear'
								bgColor='white'
								onClick={handleClearClick}>
								<Icon as={AiOutlineClose} />
							</IconButton>
						</Tooltip>
						<Tooltip label={t('search')}>
							<IconButton
								size='sm'
								bgColor='white'
								ml={2}
								aria-label='search-icon-button'
								onClick={handleSearchSubmit}>
								<AiOutlineSearch />
							</IconButton>
						</Tooltip>
					</Box>
				}
			/>
		</InputGroup>
	);
};

/** A search string can include search for tags when passed within [] brackets separated by commas.
 * For example: "andrew [work, family]" will search for contacts with the name "andrew" that also have the tags
 * "work" OR "family"
 */
// TODO: Continue: AND/OR would not work for text search, they should only work for tags and the separate between the text search and the tags array
// TODO: query should not contain more than 1 tags bracket
const parseSearchString = (string: string) => {
	const parseQuery = (queryString: string) => {
		const regex = /\[(.+?)\]/g;
		let tags: string[] = [];
		let text = queryString;
		let match: RegExpExecArray | null = null;

		while ((match = regex.exec(queryString)) !== null) {
			const newTags = match[1].split(',');

			newTags.forEach((tag) => {
				tags.push(trim(tag));
			});

			text = text.replace(match[0], '');
		}

		return { text: trim(text), tags };
	};

	return parseQuery(string);
};

/**
 * Search scenarios:
 * some text AND [tag1 OR tag2 AND tag3] >>> {$and: [{$search: "text"}, {tags: {$or: [ {$in: [tag1, tag2] }, {$in: [tag3]} ] }}]}
 */

export default EntriesSearchInput;
