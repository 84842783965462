import { $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';

const CAN_USE_DOM =
	typeof window !== 'undefined' &&
	typeof window.document !== 'undefined' &&
	typeof window.document.createElement !== 'undefined';

const useLayoutEffectImpl = CAN_USE_DOM ? React.useLayoutEffect : React.useEffect;
var useLayoutEffect = useLayoutEffectImpl;
export type OnChangeFunction = (editorStateJson: string, editorText: string) => void;

export const OnChangeDebounce: React.FC<{
	ignoreInitialChange?: boolean;
	ignoreSelectionChange?: boolean;
	onChange: OnChangeFunction;
	wait?: number;
}> = ({ ignoreInitialChange = true, ignoreSelectionChange = false, onChange, wait = 167 }) => {
	const [editor] = useLexicalComposerContext();
	let timerId: NodeJS.Timeout | null = null;

	useLayoutEffect(() => {
		return editor.registerUpdateListener(
			({ editorState, dirtyElements, dirtyLeaves, prevEditorState }) => {
				if (ignoreSelectionChange && dirtyElements.size === 0 && dirtyLeaves.size === 0) {
					return;
				}

				if (ignoreInitialChange && prevEditorState.isEmpty()) {
					return;
				}
				if (timerId === null) {
					timerId = setTimeout(() => {
						editorState.read(() => {
							const root = $getRoot();

							onChange(JSON.stringify(editorState), root.getTextContent());
						});
					}, wait);
				} else {
					clearTimeout(timerId);
					timerId = setTimeout(() => {
						editorState.read(() => {
							const root = $getRoot();
							onChange(JSON.stringify(editorState), root.getTextContent());
						});
					}, wait);
				}
			}
		);
	}, [editor, ignoreInitialChange, ignoreSelectionChange, onChange]);

	return null;
};
