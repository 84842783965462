import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AUTH_REDIRECT_URL, COGNITO_AUTH_URL, COGNITO_CLIENT_ID } from '../../config';
import axios from 'axios';
import { isExpired, decodeToken } from 'react-jwt';
import { store } from '../store';
import { LOGIN_PAGE_PATH } from '../../App';

export type UserInfo = {
	email: string;
	given_name: string;
	family_name: string;
	sub: string;
	email_verified: boolean;
	auth_time: number;
	exp: number;
	'cognito:groups': string;
	isAdmin: boolean;
} | null;

export type User = {
	_id: string;
	email: string;
	groups: string[];
	name: string;
	settings?: UserSettings;
	// TODO: Add subscription type
};

export type UserSettings = {
	limits?: {
		chatGptTokens?: Number;
	};
	usages?: {
		chatGptTokens?: Number;
	};
};

type UserSliceState = {
	userInfo: UserInfo;
};

const initialState: UserSliceState = {
	userInfo: null,
};

const ID_TOKEN_LS_KEY = 'id_token';
const ACCESS_TOKEN_LS_KEY = 'access_token';
const REFRESH_TOKEN_LS_KEY = 'refresh_token';
const EXPIRES_IN_LS_KEY = 'expires_in';

export const getCredentialsFromCode = createAsyncThunk(
	'currentUser/getCredentialsFromCode',
	async (code: string, store) => {
		let urlParames = new URLSearchParams({
			grant_type: 'authorization_code',
			redirect_uri: AUTH_REDIRECT_URL,
			client_id: COGNITO_CLIENT_ID,
			scope: 'email openid profile',
			code,
		});
		try {
			const result = await axios.post(COGNITO_AUTH_URL + '/oauth2/token', urlParames.toString(), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			});
			const { id_token, access_token, refresh_token } = result.data;

			localStorage.setItem(ID_TOKEN_LS_KEY, id_token);
			localStorage.setItem(ACCESS_TOKEN_LS_KEY, access_token);
			localStorage.setItem(REFRESH_TOKEN_LS_KEY, refresh_token);

			const { payload } = await store.dispatch(getUserInfo());

			return payload;
		} catch (e: any) {
			console.log(e.response);
		}
	}
);

const removeUserLsKeys = () => {
	localStorage.removeItem(ID_TOKEN_LS_KEY);
	localStorage.removeItem(ACCESS_TOKEN_LS_KEY);
	localStorage.removeItem(REFRESH_TOKEN_LS_KEY);
	localStorage.removeItem(EXPIRES_IN_LS_KEY);
};

export const refreshTokens = createAsyncThunk('currentUser/refreshTokens', async () => {
	let refreshToken = localStorage.getItem(REFRESH_TOKEN_LS_KEY);

	let urlParames = new URLSearchParams({
		grant_type: 'refresh_token',
		client_id: COGNITO_CLIENT_ID,
		refresh_token: refreshToken!,
	});

	try {
		const result = await axios.post(
			COGNITO_AUTH_URL + '/oauth2/token',
			urlParames.toString(),

			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		);
		const { id_token, access_token, expires_in } = result.data;
		localStorage.setItem(ID_TOKEN_LS_KEY, id_token);
		localStorage.setItem(ACCESS_TOKEN_LS_KEY, access_token);
		localStorage.setItem(EXPIRES_IN_LS_KEY, expires_in);

		return access_token;
	} catch (e: any) {
		console.log(e.response);
		removeUserLsKeys();
		window.location.replace(LOGIN_PAGE_PATH);
	}
});

axios.interceptors.request.use(async (config) => {
	const accessToken = localStorage.getItem(ACCESS_TOKEN_LS_KEY);
	const isAccessTokenExpired = !accessToken || isExpired(accessToken);

	if (!config.headers.Authorization && !isAccessTokenExpired) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	} else if (isAccessTokenExpired && !config.url?.includes(COGNITO_AUTH_URL)) {
		const newAccessToken = (await store.dispatch(refreshTokens())).payload;
		config.headers.Authorization = `Bearer ${newAccessToken}`;
	}

	return config;
});

export const getUserInfo = createAsyncThunk(
	'currentUser/getUserInfo',
	async (avoidRetry: boolean | undefined, store) => {
		const idToken = localStorage.getItem(ID_TOKEN_LS_KEY);
		const accessToken = localStorage.getItem(ACCESS_TOKEN_LS_KEY);

		try {
			if (isExpired(accessToken!) || !idToken || !accessToken) {
				if (avoidRetry) {
					return;
				}

				await store.dispatch(refreshTokens());
				const result = (await store.dispatch(getUserInfo(true))) as any;

				return result.payload;
			} else {
				const decodedToken = decodeToken(idToken) as Record<string, any>;
				return {
					...decodedToken,
					isAdmin: (decodedToken?.['cognito:groups'] as string[])?.includes('admin'),
				} as unknown as UserInfo;
			}
		} catch (e) {
			console.log(e);
		}
	}
);

export const currentUserSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		signOut: (state) => {
			removeUserLsKeys();
			state.userInfo = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserInfo.fulfilled, (state, action) => {
			state.userInfo = action.payload || null;
		});
	},
});

export const { signOut } = currentUserSlice.actions;
