import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from './current-user';
import axios from 'axios';
import { API_URL } from '../../config';

type UsersSliceState = {
	users: User[];
	isUsersLoading: boolean;
};

let initialState: UsersSliceState = {
	users: [],
	isUsersLoading: false,
};

export const getUsers = createAsyncThunk('users/get', async () => {
	const result = await axios.get(`${API_URL}/users`);

	return result.data.users;
});

export const updateUser = createAsyncThunk(
	'users/update',
	async (payload: { id: string; updates: { [path: string]: any } }) => {
		const result = await axios.put(`${API_URL}/users/${payload.id}`, {
			settings: payload.updates,
		});

		return result.data as User;
	}
);

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {},

	extraReducers: (builder) => {
		builder.addCase(getUsers.pending, (state) => {
			state.isUsersLoading = true;
		});

		builder.addCase(getUsers.fulfilled, (state, action) => {
			state.isUsersLoading = false;
			state.users = action.payload;
		});

		builder.addCase(updateUser.fulfilled, (state, action) => {
			const userIndex = state.users.findIndex((user) => user._id === action.payload._id);

			if (userIndex > -1) {
				state.users[userIndex] = { ...state.users[userIndex], ...action.payload };
			}
		});
	},
});
