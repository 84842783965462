import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../config';
import { clearAiConversation } from './ai-search';
import { clearSearchValue, saveRecentSearch } from './entries-search';
import { StoreState } from '../store';
import { createStandaloneToast } from '@chakra-ui/react';
import i18n from '../../i18n';

export type Sort = 'ascending' | 'descending';

const { toast } = createStandaloneToast();

type EntriesSliceState = {
	entries: Entry[];
	paginationHasMore: boolean;
	tags: string[];
	isEntriesLoading: boolean;
	isEntriesNewPageLoading: boolean;
	sort: Sort;
};

export type EntryUser = {
	_id: string;
	email: string;
	name: string;
};

export type Entry = {
	_id: string;
	tags?: string[];
	richContent: string;
	textOnlyContent: string;
	createdAt: string;
	updatedAt: string;
	createdBy: EntryUser;
	updatedBy: EntryUser;
	workspace?: string;
};

export type Tag = {
	_id: string;
	name: string;
};

const initialState: EntriesSliceState = {
	isEntriesLoading: false,
	isEntriesNewPageLoading: false,
	entries: [],
	paginationHasMore: false,
	tags: [],
	sort: 'descending',
};

export const ENTRIES_PAGE_LIMIT = 30;

export const fetchEntries = createAsyncThunk(
	'entries/fetchEntries',
	async (payload: { tags?: string[]; text?: string; lastEntryId?: string } | undefined, store) => {
		try {
			const { selectedWorkspaceId } = (store.getState() as StoreState).workspaces;
			const { tags, text, lastEntryId } = payload || {};

			let params: Record<string, any> = {
				limit: ENTRIES_PAGE_LIMIT,
				sort: (store.getState() as StoreState).entries.sort,
			};

			if (tags?.length || text) {
				store.dispatch(saveRecentSearch({ tags: tags || [], text: text || '' }));
			} else {
				store.dispatch(clearSearchValue());
				store.dispatch(clearAiConversation());
			}

			if (selectedWorkspaceId) {
				params.workspace = selectedWorkspaceId;
			}

			if (text) {
				params.text = text;
			}

			if (tags?.length) {
				params.tags = tags.join(',');
			}

			if (lastEntryId) {
				params.lastEntryId = lastEntryId;
			}

			const result = await axios.get(`${API_URL}/entries`, {
				params,
			});

			return result.data || [];
		} catch (e: any) {
			const error = Error(JSON.stringify(e.response));

			throw error;
		}
	}
);

export const createEntry = createAsyncThunk(
	'entries/createEntry',
	async (payload: Partial<Entry>, store) => {
		const { selectedWorkspaceId } = (store.getState() as StoreState).workspaces;

		if (selectedWorkspaceId) {
			payload.workspace = selectedWorkspaceId;
		}

		const result = await axios.post(`${API_URL}/entries`, payload);
		return result.data;
	}
);

export const deleteEntry = createAsyncThunk('entries/deleteEntry', async (id: string) => {
	await axios.delete(`${API_URL}/entries/${id}`);

	return id;
});

export const updateEntry = createAsyncThunk(
	'entries/updateEntry',
	async (payload: Partial<Entry>) => {
		const result = await axios.put(`${API_URL}/entries/${payload._id}`, payload);

		return result.data;
	}
);

export const getTags = createAsyncThunk('tags/get', async () => {
	const result = await axios.get(`${API_URL}/tags`);
	return result.data.tags;
});

export const entriesSlice = createSlice({
	name: 'entries',
	initialState,
	reducers: {
		setEntries: (state, action) => {
			state.entries = action.payload;
		},

		setEntriesSort: (state, action) => {
			state.sort = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchEntries.pending, (state, action) => {
			if (action.meta.arg?.lastEntryId) {
				state.isEntriesNewPageLoading = true;
			} else {
				state.isEntriesLoading = true;
			}
		});

		builder.addCase(fetchEntries.fulfilled, (state, action) => {
			if (action.meta.arg?.lastEntryId) {
				state.entries = [...state.entries, ...action.payload];
				state.isEntriesNewPageLoading = false;
			} else {
				state.entries = action.payload;
				state.isEntriesLoading = false;
			}

			state.paginationHasMore = action.payload.length === ENTRIES_PAGE_LIMIT;
		});

		builder.addCase(fetchEntries.rejected, (state, action) => {
			state.isEntriesLoading = false;

			const parsedError = JSON.parse(action.error.message || '{}');

			if (parsedError.status === 403) {
				toast({
					status: 'warning',
					isClosable: true,
					position: 'top',
					title: i18n.t('common:workspaceAccessRevoked'),
					duration: 90000,
				});
			}
		});

		builder.addCase(createEntry.fulfilled, (state, action) => {
			action.payload.tags?.forEach((tag: string) => {
				if (!state.tags.includes(tag)) {
					state.tags.push(tag);
				}
			});
			state.entries = [action.payload, ...state.entries];
		});

		builder.addCase(updateEntry.fulfilled, (state, action) => {
			action.payload.tags?.forEach((tag: string) => {
				if (!state.tags.includes(tag)) {
					state.tags.push(tag);
				}
			});

			const existingEntryIndex = state.entries.findIndex(
				(entry) => entry._id === action.payload._id
			);
			state.entries[existingEntryIndex] = action.payload;
		});

		builder.addCase(deleteEntry.fulfilled, (state, action) => {
			state.entries = state.entries.filter((entry) => entry._id !== action.payload);
		});

		builder.addCase(getTags.fulfilled, (state, action) => {
			state.tags = action.payload;
		});
	},
});

export const { setEntries, setEntriesSort } = entriesSlice.actions;
