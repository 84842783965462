import { useMemo, useState } from 'react';
import { PLACEHOLDER_PAGE_ID, Page } from '../../../../redux/slices/pages';
import { Box, Card, CardBody, Divider, IconButton, Tag, TagLabel, Text } from '@chakra-ui/react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../redux/store';
import moment from 'moment';

interface Props {
	onClick: (page: Page) => void;
	onDeleteClick: (page: Page) => void;
	onEditClick: (page: Page) => void;
	index: number;
	page: Page;
}

const PageEntry: React.FC<Props> = ({ onClick, onDeleteClick, onEditClick, index, page }) => {
	const [isHovered, setIsHovered] = useState(false);
	const [, drag] = useDrag(() => ({ type: 'box', item: page }));
	const { searchValue } = useSelector((state: StoreState) => state.entriesSearch);

	const isSelected = useMemo(() => searchValue._id === page._id, [searchValue, page]);

	if (page._id === PLACEHOLDER_PAGE_ID) {
		return null;
	}

	return (
		<>
			<Card
				color={isSelected ? 'white' : ''}
				bgColor={isSelected ? 'gray.700' : 'white'}
				ref={drag}
				cursor='pointer'
				minHeight='50px'
				size='sm'
				boxShadow='none'
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				onClick={() => onClick(page)}>
				<CardBody>
					<Box display='flex'>
						<Box width='90%'>
							{page.title && <Text fontSize='sm'>{page.title}</Text>}
							{page.searchText && <Text fontSize='xs'>{page.searchText}</Text>}
							{Boolean(page.tags.length) && (
								<Box display='flex' gap={2} mt={page.searchText ? 2 : 0}>
									{page.tags?.map((tag, index) => (
										<Tag size='sm' key={index}>
											<TagLabel>{tag}</TagLabel>
										</Tag>
									))}
								</Box>
							)}
							<Text fontSize='xs' color={isSelected ? 'gray.100' : 'GrayText'} mt={1}>
								{moment(page.createdAt).fromNow()}
							</Text>
						</Box>
						{isHovered && (
							<Box color='black' gap={2} display='flex' alignItems='center'>
								<IconButton
									size='xs'
									aria-label='edit-icon-button'
									onClick={(e) => {
										e.stopPropagation();

										onEditClick(page);
									}}>
									<AiOutlineEdit />
								</IconButton>
								<IconButton
									size='xs'
									aria-label='delete-icon-button'
									onClick={(e) => {
										e.stopPropagation();

										onDeleteClick(page);
									}}>
									<AiOutlineDelete />
								</IconButton>
							</Box>
						)}
					</Box>
				</CardBody>
			</Card>
			<Divider />
		</>
	);
};

export default PageEntry;
