import { Input, Tooltip, IconButton, Button, Box, Text, Icon } from '@chakra-ui/react';
import { AiOutlineClear, AiOutlineSave, AiOutlineSend } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { Entry, createEntry } from '../../../redux/slices/entries';
import { StoreState, useAppDispatch } from '../../../redux/store';
import { useMemo, useState } from 'react';
import { AI_GENERATED_TAG_NAME } from '../../../config';
import { useTranslation } from 'react-i18next';
import {
	AiConversationMessage,
	addAiConversationMessage,
	clearAiConversation,
	sendMessagesToAi,
} from '../../../redux/slices/ai-search';

const Inputs = () => {
	const [messageInputValue, setMessageInputValue] = useState('');
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const isAiSearchLoading = useSelector((state: StoreState) => state.aiSearch.isAiSearchLoading);
	const messages = useSelector((state: StoreState) => state.aiSearch.aiConversationMessages);
	const settings = useSelector((state: StoreState) => state.generalSettings.settings);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const aiConversationMessages = useSelector(
		(state: StoreState) => state.aiSearch.aiConversationMessages
	);

	const aiRequestsLimitReached = useSelector(
		(state: StoreState) => state.aiSearch.aiRequestsLimitReached
	);

	const isConvoMessagesLimitReached = useMemo(() => {
		const limit = settings?.chatGpt?.maxNoOfMessagesPerConversation;

		if (
			limit &&
			messages.filter((message) => message.role === 'user' && !message.isDenied).length >= limit
		) {
			return true;
		}

		return false;
	}, [messages]);

	const isSaveButtonDisabled = useMemo(
		() =>
			!Boolean(
				aiConversationMessages.filter(
					(message) => message.role === 'assistant' && !message.isRemoved
				)?.length
			),
		[aiConversationMessages]
	);

	const handleMessageSend = () => {
		if (
			!messageInputValue ||
			isAiSearchLoading ||
			aiRequestsLimitReached ||
			isConvoMessagesLimitReached
		) {
			return;
		}

		const newMessage: AiConversationMessage = {
			role: 'user',
			content: messageInputValue,
		};

		dispatch(addAiConversationMessage(newMessage));
		dispatch(sendMessagesToAi([...aiConversationMessages, newMessage]));

		setMessageInputValue('');
	};

	const handleSaveClick = async () => {
		let entryContent = '';

		aiConversationMessages.forEach((message) => {
			if (message.role === 'assistant' && !message.isRemoved) {
				entryContent += `${entryContent ? '\n\n' : ''}${message.content}`;
			}
		});

		let newEntry: Partial<Entry> = {
			textOnlyContent: entryContent,
			tags: [AI_GENERATED_TAG_NAME],
			richContent: JSON.stringify({
				root: {
					children: [
						{
							children: [
								{
									detail: 0,
									format: 0,
									mode: 'normal',
									style: '',
									text: entryContent,
									type: 'text',
									version: 1,
								},
							],
							direction: 'ltr',
							format: '',
							indent: 0,
							type: 'paragraph',
							version: 1,
						},
					],
					direction: 'ltr',
					format: '',
					indent: 0,
					type: 'root',
					version: 1,
				},
			}),
		};

		setIsSaveLoading(true);
		await dispatch(createEntry(newEntry));
		dispatch(clearAiConversation());
		setIsSaveLoading(false);
	};

	return (
		<Box>
			<Box mt={6} display='flex' alignItems='center'>
				<Tooltip label={isConvoMessagesLimitReached ? t('convoMessagesLimitReached') : ''}>
					<Input
						value={messageInputValue}
						width={400}
						isDisabled={isAiSearchLoading || aiRequestsLimitReached || isConvoMessagesLimitReached}
						placeholder={t('enterMessage') as string}
						onChange={(e) => setMessageInputValue(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleMessageSend();
							}
						}}
					/>
				</Tooltip>

				<Tooltip label={isConvoMessagesLimitReached ? t('convoMessagesLimitReached') : t('send')}>
					<IconButton
						isDisabled={isAiSearchLoading || aiRequestsLimitReached || isConvoMessagesLimitReached}
						ml={2}
						aria-label='send-icon-button'
						onClick={handleMessageSend}>
						<AiOutlineSend />
					</IconButton>
				</Tooltip>

				<Box ml='auto' width='fit-content' display='flex' gap={4}>
					<Button
						leftIcon={<Icon as={AiOutlineClear} />}
						size='sm'
						disabled={isSaveLoading || isAiSearchLoading}
						onClick={() => dispatch(clearAiConversation())}>
						{t('clearConversation')}
					</Button>
					<Button
						leftIcon={<Icon as={AiOutlineSave} />}
						isDisabled={isAiSearchLoading || isSaveButtonDisabled}
						size='sm'
						isLoading={isSaveLoading}
						onClick={handleSaveClick}>
						{t('saveResult')}
					</Button>
				</Box>
			</Box>

			{aiRequestsLimitReached && (
				<Text mt={2} ml={4} fontSize={12} color='red'>
					{t('chatGptRequestsLimitReached')}
				</Text>
			)}
		</Box>
	);
};

export default Inputs;
