import { Tooltip, IconButton, Box, Text, ListItem } from '@chakra-ui/react';
import { BsTrash } from 'react-icons/bs';
import { Workspace } from '../../../redux/slices/workspaces';
import { useState } from 'react';
import { User } from '../../../redux/slices/current-user';
import axios from 'axios';
import { API_URL } from '../../../config';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../redux/store';

interface Props {
	user: User;
	workspace: Workspace;
	onRemove: () => void;
}

const WorkspaceUserItem: React.FC<Props> = ({ user, workspace, onRemove }) => {
	const userInfo = useSelector((state: StoreState) => state.currentUser.userInfo);
	const [isRemoveLoading, setIsRemoveLoading] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const { t } = useTranslation();

	const handleRemoveClick = async () => {
		setIsRemoveLoading(true);

		await axios.delete(`${API_URL}/workspaces/${workspace._id}/users/${user._id}`);

		setIsRemoveLoading(false);

		onRemove();
	};

	return (
		<ListItem
			cursor='default'
			display='flex'
			px={4}
			py={2}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			bg='gray.100'
			borderRadius={4}
			mt={2}>
			<Box>
				<Text>{user.name}</Text>
				<Text fontSize='xs'>{user.email}</Text>
			</Box>

			{isHovered && userInfo?.isAdmin && (
				<Box w='fit-content' ml='auto'>
					<Tooltip label={t('removeUserFromWorkspace')}>
						<IconButton
							isLoading={isRemoveLoading}
							onClick={handleRemoveClick}
							aria-label='remove-user'>
							<BsTrash />
						</IconButton>
					</Tooltip>
				</Box>
			)}
		</ListItem>
	);
};

export default WorkspaceUserItem;
