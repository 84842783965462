import ExampleTheme from './themes/ExampleTheme';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import '../../editor-styles.css';
import { OnChangeDebounce, OnChangeFunction } from './plugins/OnChangeDebouncePlugin';
import { useMemo } from 'react';
import UpdatePlugin from './plugins/UpdatePlugin';
import CommandsPlugin from './plugins/CommandsPlugin';

function Placeholder() {
	return <div className='editor-placeholder'>Enter text...</div>;
}

interface Props {
	onChange?: OnChangeFunction;
	readOnly?: boolean;
	defaultValue?: string;
	value?: string;
}

const RichTextEditor: React.FC<Props> = (props) => {
	const { value, defaultValue, readOnly, onChange } = props;

	const editorConfig: InitialConfigType = useMemo(
		() => ({
			namespace: 'test',
			editorState: defaultValue,
			// The editor theme
			theme: ExampleTheme,
			editable: !readOnly,
			// Handling of errors during update
			onError(error: Error) {
				throw error;
			},
			// Any custom nodes go here
			nodes: [
				HeadingNode,
				ListNode,
				ListItemNode,
				QuoteNode,
				CodeNode,
				CodeHighlightNode,
				TableNode,
				TableCellNode,
				TableRowNode,
				AutoLinkNode,
				LinkNode,
			],
		}),
		[]
	);

	return (
		<LexicalComposer initialConfig={editorConfig}>
			<div className='editor-container'>
				{!readOnly && <ToolbarPlugin />}
				<div className='editor-inner'>
					<RichTextPlugin
						contentEditable={<ContentEditable className='editor-input' />}
						placeholder={<Placeholder />}
						ErrorBoundary={LexicalErrorBoundary}
					/>
					{!readOnly && (
						<>
							<CommandsPlugin />
							<HistoryPlugin />
							<AutoFocusPlugin />
							{onChange && (
								<OnChangeDebounce ignoreInitialChange ignoreSelectionChange onChange={onChange} />
							)}
							{/* <CodeHighlightPlugin /> */}
							<ListPlugin />
							<LinkPlugin />
							{/* <AutoLinkPlugin /> */}
							{/* <ListMaxIndentLevelPlugin maxDepth={7} /> */}
							<MarkdownShortcutPlugin transformers={TRANSFORMERS} />
							{value && <UpdatePlugin value={value} />}
						</>
					)}
				</div>
			</div>
		</LexicalComposer>
	);
};

export default RichTextEditor;
