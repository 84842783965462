import {
	Button,
	Icon,
	IconButton,
	List,
	ListItem,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverFooter,
	PopoverHeader,
	PopoverTrigger,
	Radio,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { Workspace } from '../../../redux/slices/workspaces';
import { useMemo, useState } from 'react';
import { GrAdd } from 'react-icons/gr';
import SearchInput from '../../Inputs/SearchInput';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../redux/store';
import axios from 'axios';
import { API_URL } from '../../../config';
import { User } from '../../../redux/slices/current-user';

interface Props {
	user: User;
	userWorkspaces: Workspace[];
	onAdd: (workspace: Workspace) => void;
}

const AddWorkspacePopover: React.FC<Props> = ({ user, userWorkspaces, onAdd }) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');
	const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(null);
	const [isAddLoading, setIsAddLoading] = useState(false);
	const workspaces = useSelector((state: StoreState) => state.workspaces.workspaces);
	const { isOpen, onClose, onToggle } = useDisclosure();

	const filteredWorkspaces = useMemo(() => {
		if (searchValue) {
			const lowerCaseSearchValue = searchValue.toLowerCase();

			return workspaces.filter((workspace) =>
				workspace.name.toLowerCase().includes(lowerCaseSearchValue)
			);
		} else {
			return workspaces;
		}
	}, [workspaces, searchValue]);

	const handleAddClick = async () => {
		if (selectedWorkspace) {
			setIsAddLoading(true);
			await axios.post(`${API_URL}/workspaces/${selectedWorkspace._id}/users`, {
				userId: user._id,
			});

			onAdd(selectedWorkspace);
			onClose();

			setIsAddLoading(false);
		}
	};

	return (
		<Popover
			returnFocusOnClose={false}
			isOpen={isOpen}
			onClose={onClose}
			placement='auto'
			closeOnBlur={false}>
			<PopoverTrigger>
				<Tooltip label={t('addToWorkspace')}>
					<IconButton ml={2} onClick={onToggle} aria-label='add' size='sm'>
						<Icon as={GrAdd} />
					</IconButton>
				</Tooltip>
			</PopoverTrigger>
			<PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
				<PopoverHeader pt={4} fontWeight='bold' border='0'>
					{t('addToWorkspace')}
				</PopoverHeader>
				<PopoverArrow bg='blue.800' />
				<PopoverCloseButton />
				<PopoverBody>
					<SearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
					<List height={300} overflowY='auto'>
						{filteredWorkspaces.map((workspace, index) => {
							const isDisabled = Boolean(userWorkspaces.find(({ _id }) => _id === workspace._id));

							return (
								<ListItem
									onClick={() => !isDisabled && setSelectedWorkspace(workspace)}
									borderRadius={4}
									_hover={{ bg: !isDisabled ? 'whiteAlpha.800' : '' }}
									bg='white'
									mt={2}
									px={4}
									py={2}
									display='flex'
									opacity={isDisabled ? 0.5 : 1}
									cursor={!isDisabled ? 'pointer' : ''}
									key={index}>
									<Radio mr={4} isChecked={selectedWorkspace?._id === workspace._id} />
									<Text color='black'>{workspace.name}</Text>
								</ListItem>
							);
						})}
					</List>
				</PopoverBody>
				<PopoverFooter
					border='0'
					display='flex'
					alignItems='center'
					justifyContent='space-between'
					pb={4}>
					<Button
						isLoading={isAddLoading}
						onClick={handleAddClick}
						isDisabled={!selectedWorkspace}
						colorScheme='blue'>
						Add
					</Button>
				</PopoverFooter>
			</PopoverContent>
		</Popover>
	);
};

export default AddWorkspacePopover;
