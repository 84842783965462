import { configureStore } from '@reduxjs/toolkit';
import { entriesSlice } from './slices/entries';
import { useDispatch } from 'react-redux';
import { currentUserSlice } from './slices/current-user';
import { aiSearchSlice } from './slices/ai-search';
import { entriesSearchSlice } from './slices/entries-search';
import { pagesSlice } from './slices/pages';
import { workspacesSlice } from './slices/workspaces';
import { usersSlice } from './slices/users';
import { generalSettingsSlice } from './slices/general-settings';

export const store = configureStore({
	reducer: {
		entries: entriesSlice.reducer,
		entriesSearch: entriesSearchSlice.reducer,
		pages: pagesSlice.reducer,
		currentUser: currentUserSlice.reducer,
		aiSearch: aiSearchSlice.reducer,
		workspaces: workspacesSlice.reducer,
		users: usersSlice.reducer,
		generalSettings: generalSettingsSlice.reducer
	},
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export type StoreState = ReturnType<typeof store.getState>;
