export const API_URL = 'https://ef8kwfai31.execute-api.eu-west-2.amazonaws.com/v1';
export const COGNITO_CLIENT_ID = '5uaqomg9f3u53hv43hbta68qak';
export const USER_POOL_ID = 'eu-west-2_tzPKvTitB';
export const AUTH_REDIRECT_URL =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:3000/login'
		: 'https://main.taggededitor.com/login';
export const COGNITO_AUTH_URL = 'https://taggededitor01.auth.eu-west-2.amazoncognito.com';
export const APP_NAME = 'Tagged Editor';

export const AI_GENERATED_TAG_NAME = 'AI_Generated';
