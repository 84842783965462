import {
	Popover,
	Button,
	PopoverContent,
	PopoverHeader,
	PopoverArrow,
	PopoverCloseButton,
	PopoverBody,
	PopoverFooter,
	useDisclosure,
	PopoverTrigger,
	Box,
	CircularProgress,
	Text,
	Radio,
	ListItem,
	List,
	useToast,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { GrFormAdd } from 'react-icons/gr';
import { User } from '../../../redux/slices/current-user';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../../config';
import axios from 'axios';
import { Workspace } from '../../../redux/slices/workspaces';
import SearchInput from '../../Inputs/SearchInput';
import { useSelector } from 'react-redux';
import { StoreState, useAppDispatch } from '../../../redux/store';
import { getUsers } from '../../../redux/slices/users';

interface Props {
	workspace: Workspace;
	workspaceUsers: User[];
	onAdd: (user: User) => void;
}

const AddUserPopover: React.FC<Props> = ({ workspace, workspaceUsers, onAdd }) => {
	const [searchValue, setSearchValue] = useState('');
	const { isOpen, onToggle, onClose } = useDisclosure();
	const { t } = useTranslation();
	const { users, isUsersLoading } = useSelector((state: StoreState) => state.users);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [isAddLoading, setIsAddLoading] = useState(false);
	const dispatch = useAppDispatch();
	const toast = useToast();

	const filteredUsers = useMemo(() => {
		if (!searchValue) {
			return users;
		}

		const lowerCaseSearchValue = searchValue.toLowerCase();

		return users.filter(
			(user) =>
				user.name.toLowerCase().includes(lowerCaseSearchValue) ||
				user.email.includes(lowerCaseSearchValue)
		);
	}, [users, searchValue]);

	const handleAddClick = async () => {
		if (!selectedUser) {
			return;
		}

		setIsAddLoading(true);

		try {
			await axios.post(`${API_URL}/workspaces/${workspace._id}/users`, {
				userId: selectedUser._id,
			});

			onAdd(selectedUser);
		} catch (e: any) {
			if (e.response.data?.error) {
				toast({
					title: t(e.response.data?.error),
					status: 'error',
					duration: 9000,
					position: 'top',
					isClosable: true,
				});
			}
		}

		setIsAddLoading(false);
		onClose();
	};

	useEffect(() => {
		if (isOpen && !users.length) {
			dispatch(getUsers());
		}
	}, [isOpen]);

	return (
		<Popover
			returnFocusOnClose={false}
			isOpen={isOpen}
			onClose={onClose}
			placement='top'
			closeOnBlur={false}>
			<PopoverTrigger>
				<Button onClick={onToggle} leftIcon={<GrFormAdd />}>
					{t('addUser')}
				</Button>
			</PopoverTrigger>
			<PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
				<PopoverHeader pt={4} fontWeight='bold' border='0'>
					{t('addUser')}
				</PopoverHeader>
				<PopoverArrow bg='blue.800' />
				<PopoverCloseButton />
				<PopoverBody>
					{isUsersLoading && (
						<Box w='fit-content' mx='auto'>
							<CircularProgress isIndeterminate size='30px' />
						</Box>
					)}

					{!isUsersLoading && (
						<SearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
					)}
					<List maxHeight={500} overflowY='auto'>
						{!isUsersLoading &&
							filteredUsers.map((user, index) => {
								const isDisabled = Boolean(workspaceUsers.find(({ _id }) => _id === user._id));

								return (
									<ListItem
										onClick={() => !isDisabled && setSelectedUser(user)}
										borderRadius={4}
										_hover={{ bg: !isDisabled ? 'whiteAlpha.800' : '' }}
										bg='white'
										mt={2}
										px={4}
										py={2}
										display='flex'
										opacity={isDisabled ? 0.5 : 1}
										cursor={!isDisabled ? 'pointer' : ''}
										key={index}>
										<Radio mr={4} isChecked={selectedUser?._id === user._id} />
										<Box>
											<Text color='black'>{user.name}</Text>
											<Text color='black' fontSize='xs'>
												{user.email}
											</Text>
										</Box>
									</ListItem>
								);
							})}
					</List>
				</PopoverBody>
				<PopoverFooter
					border='0'
					display='flex'
					alignItems='center'
					justifyContent='space-between'
					pb={4}>
					<Button
						isLoading={isAddLoading}
						onClick={handleAddClick}
						isDisabled={!selectedUser}
						colorScheme='blue'>
						Add
					</Button>
				</PopoverFooter>
			</PopoverContent>
		</Popover>
	);
};

export default AddUserPopover;
