import { Icon, CircularProgress, Box, Text, Tooltip, IconButton } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { AiConversationMessage } from '../../../redux/slices/ai-search';
import { useTranslation } from 'react-i18next';
import { BiErrorCircle } from 'react-icons/bi';
import { BsPerson, BsRobot } from 'react-icons/bs';

const Message: React.FC<{
	message: AiConversationMessage;
	isLoading?: boolean;
	onRemoveClick?: () => void;
}> = ({ message, isLoading, onRemoveClick }) => {
	const { t } = useTranslation();

	if (message.isRemoved) {
		return null;
	}

	return (
		<Box mb={1} fontSize={12} backgroundColor='gray.100' padding={3} borderRadius={2}>
			<Box mb={2} display='flex' alignItems='center'>
				<Icon as={message.role === 'user' ? BsPerson : BsRobot} mr={2} />

				<Text color={message.isDenied ? 'red' : ''} fontWeight={700} textTransform='capitalize'>
					{message.role === 'user' ? 'You' : message.role}
				</Text>

				{message.isDenied && (
					<Tooltip label={t('messageDenied')}>
						<Box mb={-1} ml={2}>
							<Icon color='red' as={BiErrorCircle} fontSize='md' />
						</Box>
					</Tooltip>
				)}

				{message.role === 'assistant' && !isLoading && onRemoveClick && (
					<Box width='fit-content' ml='auto'>
						<Tooltip label={t('remove') as string}>
							<IconButton size='xs' aria-label='remove'>
								<Icon onClick={onRemoveClick} cursor='pointer' as={AiOutlineClose} />
							</IconButton>
						</Tooltip>
					</Box>
				)}
			</Box>
			<Box display='flex' alignItems='center' gap={4}>
				<Text fontWeight={400}>{message.content}</Text>

				{isLoading && <CircularProgress size='small' />}
			</Box>
		</Box>
	);
};

export default Message;
