import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL } from '../../config';
import axios from 'axios';

type AiSearchSliceState = {
	isAiSearchLoading: boolean;
	aiRequestsLimitReached: boolean;
	aiConversationMessages: AiConversationMessage[];
};

export type AiConversationMessage = {
	role: 'user' | 'assistant';
	content: string;
	isRemoved?: boolean;
	isDenied?: boolean;
};

const initialState: AiSearchSliceState = {
	isAiSearchLoading: false,
	aiConversationMessages: [],
	aiRequestsLimitReached: false,
};

export const sendMessagesToAi = createAsyncThunk(
	'ai-search/send-messages',
	async (payload: AiConversationMessage[]) => {
		try {
			const result = await axios.post(`${API_URL}/chat-gpt`, {
				messages: payload
					.filter((message) => !message.isDenied)
					.map((message) => {
						const { role, content } = message;

						return {
							role,
							content,
						};
					}),
			});

			return result.data.choices[0]?.message?.content || '';
		} catch (e: any) {
			const error = Error(JSON.stringify(e.response));

			throw error;
		}
	}
);

export const aiSearchSlice = createSlice({
	name: 'aiSearch',
	initialState,
	reducers: {
		clearAiConversation: (state) => {
			state.aiConversationMessages = [];
		},

		addAiConversationMessage: (state, action: { payload: AiConversationMessage }) => {
			state.aiConversationMessages.push(action.payload);
		},

		setAiConversationMessages: (state, action: { payload: AiConversationMessage[] }) => {
			state.aiConversationMessages = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(sendMessagesToAi.pending, (state) => {
			state.isAiSearchLoading = true;
		});

		builder.addCase(sendMessagesToAi.fulfilled, (state, action) => {
			state.isAiSearchLoading = false;

			state.aiConversationMessages.push({
				role: 'assistant',
				content: action.payload,
			});
		});
		builder.addCase(sendMessagesToAi.rejected, (state, action) => {
			state.isAiSearchLoading = false;
			const parsedError = JSON.parse(action.error.message || '{}');

			if (parsedError.status === 403) {
				state.aiRequestsLimitReached = true;
			} else if (parsedError.data.error === 'UserMessageDenied') {
				state.aiConversationMessages[state.aiConversationMessages.length - 1] = {
					...state.aiConversationMessages[state.aiConversationMessages.length - 1],
					isDenied: true,
				};
			}
		});
	},
});

export const { clearAiConversation, addAiConversationMessage, setAiConversationMessages } =
	aiSearchSlice.actions;
