export const isMacOS = () => {
	//@ts-ignore
	return navigator?.userAgentData?.platform === 'Mac' || navigator?.platform?.match('Mac');
};

export const setCaretPosition = (element: HTMLInputElement, caretPos: number) => {
	// Modern browsers
	if (element.setSelectionRange) {
		element.focus();
		element.setSelectionRange(caretPos, caretPos);

		// IE8 and below
		// @ts-ignore
	} else if (element.createTextRange) {
		// @ts-ignore
		var range = element.createTextRange();
		range.collapse(true);
		range.moveEnd('character', caretPos);
		range.moveStart('character', caretPos);
		range.select();
	}
};

export const replaceRange = (s: string, start: number, end: number, substitute: string) => {
	return s.substring(0, start) + substitute + s.substring(end);
};

export const removeSpacesFromString = (string: string) => {
	return string.replace(/ /g, '');
};
