import { useSelector } from 'react-redux';
import PageContainer from '../Components/PageContainer';
import { StoreState, useAppDispatch } from '../redux/store';
import { Box, Button, Card, CardBody, CircularProgress, Text, Tooltip } from '@chakra-ui/react';
import { useEffect, useMemo, useState, useRef } from 'react';
import EntryItem from '../Components/Home/EntryItem/EntryItem';
import { ENTRIES_PAGE_LIMIT, fetchEntries, getTags } from '../redux/slices/entries';
import ResultSummary from '../Components/Home/ResultSummary';
import { useTranslation } from 'react-i18next';
import AISearchResult from '../Components/Home/AiConversation/AiConversation';
import EntriesSort from '../Components/Home/Sort';
import AddButton from '../Components/Home/AddButton';
import EntriesSearchInput from '../Components/Inputs/EntriesSearchInput';

const HomePage = () => {
	const { t } = useTranslation();
	const [newEntry, setNewEntry] = useState(false);
	const [focusedEntryId, setFocusedEntryId] = useState<string | null>(null);
	const entries = useSelector((state: StoreState) => state.entries.entries);
	const { settings, isLoading: isSettingsLoading } = useSelector(
		(state: StoreState) => state.generalSettings
	);
	const sort = useSelector((state: StoreState) => state.entries.sort);
	const searchValue = useSelector((state: StoreState) => state.entriesSearch.searchValue);
	const isEntriesLoading = useSelector((state: StoreState) => state.entries.isEntriesLoading);
	const hasMore = useSelector((state: StoreState) => state.entries.paginationHasMore);
	const isEntriesNewPageLoading = useSelector(
		(state: StoreState) => state.entries.isEntriesNewPageLoading
	);
	const { selectedWorkspaceId, isWorkspacesLoading, workspaces } = useSelector(
		(state: StoreState) => state.workspaces
	);
	const dispatch = useAppDispatch();
	const containerRef = useRef<HTMLDivElement | null>(null);

	const selectedWorkspace = useMemo(
		() => workspaces.find((workspace) => workspace._id === selectedWorkspaceId),
		[workspaces, selectedWorkspaceId]
	);

	const isLoading = isEntriesLoading || isWorkspacesLoading || isSettingsLoading;

	const loadEntries = async (nextPage?: boolean) => {
		await dispatch(
			fetchEntries({
				...searchValue,
				lastEntryId: nextPage ? entries[entries.length - 1]?._id : undefined,
			})
		);
	};

	useEffect(() => {
		dispatch(getTags());
	}, []);

	useEffect(() => {
		if (settings && settings.application?.showMostRecentEntriesOnHomePage) {
			loadEntries();
		}
	}, [sort, selectedWorkspaceId, settings]);

	useEffect(() => {
		/** Highlights text matching searchValue */
		// const highlight = (text: string) => {
		// 	if (!containerRef.current) {
		// 		return;
		// 	}
		// 	let innerHTML = containerRef.current.innerHTML;

		// 	text.split(' ').forEach((word) => {
		// 		let index = innerHTML.indexOf(word);

		// 		if (index >= 0) {
		// 			innerHTML =
		// 				innerHTML.substring(0, index) +
		// 				"<span class='highlight'>" +
		// 				innerHTML.substring(index, index + word.length) +
		// 				'</span>' +
		// 				innerHTML.substring(index + word.length);
		// 			containerRef.current!.innerHTML = innerHTML;
		// 		}
		// 	});
		// };

		// if (searchValue.text && !isEntriesLoading) {
		// 	highlight(searchValue.text);
		// }
	}, [searchValue, isEntriesLoading]);

	const NewEntryItem = () => {
		if (newEntry) {
			return (
				<EntryItem
					key={sort}
					defaultEditMode
					onCancel={() => setNewEntry(false)}
					defaultTags={entries[0]?.tags || searchValue.tags}
					onMoveToNextEntry={() => {
						if (sort === 'descending') {
							setFocusedEntryId(entries[0]._id);
						}
					}}
					onMoveToPrevEntry={() => {
						if (sort === 'ascending') {
							if (entries[entries.length - 1]) {
								setFocusedEntryId(entries[entries.length - 1]?._id);
							} else {
								setTimeout(() => {
									setNewEntry(true);
								}, 10);
							}
						}
					}}
				/>
			);
		}

		return (
			<AddButton
				onClick={() => {
					setNewEntry(true);
				}}
			/>
		);
	};

	return (
		<PageContainer>
			<Box display='flex' alignItems='center' mb={4}>
				{!isWorkspacesLoading && (
					<Tooltip label={t('selectedWorkspace')}>
						<Text width='fit-content' fontSize='2xl'>
							{selectedWorkspace?.name || t('allOrganization')}
						</Text>
					</Tooltip>
				)}
				<Box gap={2} w='fit-content' ml='auto' display='flex' alignItems='center'>
					<EntriesSort />
					<EntriesSearchInput isDisabled={isLoading} style={{ width: 350 }} />
				</Box>
			</Box>
			<ResultSummary />

			{isLoading && (
				<Box mt={6} width='fit-content' mx='auto'>
					<CircularProgress size='25px' isIndeterminate />
				</Box>
			)}

			{!isLoading && (
				<>
					<AISearchResult />
				</>
			)}

			{!isLoading &&
			(settings?.application?.showMostRecentEntriesOnHomePage ||
				Boolean(searchValue.text) ||
				Boolean(searchValue.tags.length)) ? (
				<>
					{!isLoading && entries.length === 0 && !newEntry && (
						<Card mt={4}>
							<CardBody>
								<Text textAlign='center'>{t('noEntriesFound')}</Text>
							</CardBody>
						</Card>
					)}

					{sort === 'descending' && <NewEntryItem />}

					<Box ref={containerRef}>
						{!isLoading &&
							entries.map((entry, index) => (
								<EntryItem
									paddingTop={index === 0 ? 4 : 1}
									paddingBottom={index + 1 === entries.length ? 4 : 1}
									entry={entry}
									key={entry._id}
									defaultEditMode={focusedEntryId === entry._id}
									onMoveToNextEntry={() => {
										if (entries[index + 1]) {
											setFocusedEntryId(entries[index + 1]._id);
										} else {
											setNewEntry(true);
										}
									}}
									onMoveToPrevEntry={() => {
										if (entries[index - 1]) {
											setFocusedEntryId(entries[index - 1]._id);
										} else {
											setNewEntry(true);
										}
									}}
									onEditModeChange={(isEnabled) => {
										if (isEnabled) {
											setFocusedEntryId(entry._id);
										} else if (focusedEntryId === entry._id) {
											setFocusedEntryId(null);
										}
									}}
								/>
							))}
					</Box>
					{sort === 'ascending' && !hasMore && <NewEntryItem />}

					{!isLoading && !isEntriesNewPageLoading && (
						<Box mt={6} w='fit-content' mx='auto'>
							{hasMore && (
								<Button size='xs' onClick={() => loadEntries(true)}>
									{t('loadMore')}
								</Button>
							)}

							{!hasMore && entries.length > ENTRIES_PAGE_LIMIT && (
								<Text fontSize='sm'>{t('pageEndReached')}</Text>
							)}
						</Box>
					)}

					{isEntriesNewPageLoading && (
						<Box mt={6} width='fit-content' mx='auto'>
							<CircularProgress size='25px' isIndeterminate />
						</Box>
					)}
				</>
			) : (
				!isLoading && <Text textAlign='center'>{t('selectTagOrSearch')}</Text>
			)}
		</PageContainer>
	);
};

export default HomePage;
