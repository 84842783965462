import { Box, Button, Card, CardBody, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../redux/store';

const SubscriptionInfo = () => {
	const workspaces = useSelector((state: StoreState) => state.workspaces.workspaces);
	const { subscription, usages, settings } = useSelector(
		(state: StoreState) => state.generalSettings
	);
	const { t } = useTranslation();

	return (
		<Card mt={6}>
			<CardBody>
				<Box display='flex' alignItems='center'>
					<Text fontSize='xl' fontWeight={500}>
						{t('currentSubscription')}
					</Text>
					<Box w='fit-content' ml='auto'>
						<Button size='sm' onClick={() => alert('To be implemented')} colorScheme='blue'>
							{t('manageSubscription')}
						</Button>
					</Box>
				</Box>

				<Text mt={4} fontWeight={400}>
					{subscription?.label}
				</Text>

				<Text mt={6} fontSize='xl' fontWeight={500}>
					{t('usagesAndLimits')}
				</Text>

				<Box mt={4}>
					{subscription?.limits.chatGptTokens && (
						<UsageEntry
							label={t('chatGptTokens')}
							usage={usages?.chatGptTokens || 0}
							limit={subscription?.limits.chatGptTokens}
						/>
					)}
					{subscription?.limits.users && (
						<UsageEntry
							label={t('users')}
							usage={usages?.users || 0}
							limit={subscription?.limits.users}
						/>
					)}
					{subscription?.limits.workspaces && (
						<UsageEntry
							label={t('workspaces')}
							usage={workspaces.length || usages?.workspaces || 0}
							limit={subscription?.limits.workspaces}
						/>
					)}

					{subscription?.limits.allowWords && (
						<UsageEntry
							label={t('allowWords')}
							usage={settings?.aiSearchFilter?.allowWords?.length || 0}
							limit={subscription?.limits.allowWords}
						/>
					)}

					{subscription?.limits.denyWords && (
						<UsageEntry
							label={t('denyWords')}
							usage={settings?.aiSearchFilter?.denyWords?.length || 0}
							limit={subscription?.limits.denyWords}
						/>
					)}
				</Box>
			</CardBody>
		</Card>
	);
};

const UsageEntry: React.FC<{ label: string; usage: number; limit: number }> = ({
	label,
	usage,
	limit,
}) => {
	const textColor = usage >= limit ? 'red' : '';
	return (
		<Box color={textColor} mt={2} display='flex' alignItems='center' gap={8}>
			<Text width={130} fontSize={14} fontWeight={500}>
				{label}
			</Text>
			<Text fontSize={14} fontWeight={400}>
				{usage} / {limit}
			</Text>
		</Box>
	);
};

export default SubscriptionInfo;
