import { Box, Card, CardBody, CardHeader, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
	title: string;
	Icon: ReactNode;
	children: ReactNode;
}

const SettingsSection: React.FC<Props> = ({ title, Icon, children }) => {
	return (
		<Card mt={6}>
			<CardHeader>
				<Box display='flex' alignItems='center'>
					{Icon}
					<Text ml={4} fontSize='xl' fontWeight={500}>
						{title}
					</Text>
				</Box>
			</CardHeader>
			<CardBody pt={0}>{children}</CardBody>
		</Card>
	);
};

export default SettingsSection;
