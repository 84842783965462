import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	Box,
	Text,
	CircularProgress,
	List,
} from '@chakra-ui/react';
import { Workspace } from '../../../redux/slices/workspaces';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../redux/slices/current-user';
import axios from 'axios';
import { API_URL } from '../../../config';
import AddUserPopover from './AddUserPopover';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../redux/store';
import WorkspaceUserItem from './WorkspaceUserItem';
import SearchInput from '../../Inputs/SearchInput';

interface Props {
	isOpen: boolean;
	selectedWorkspace: Workspace;
	onClose: () => void;
}

const WorkspaceUsersModal: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { isOpen, selectedWorkspace, onClose } = props;
	const userInfo = useSelector((state: StoreState) => state.currentUser.userInfo);
	const [workspaceUsers, setWorkspaceUsers] = useState<User[]>([]);
	const [searchValue, setSearchValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const filteredUsers = useMemo(() => {
		if (searchValue) {
			const lowerCaseValue = searchValue.toLowerCase();

			return workspaceUsers.filter(
				(user) =>
					user.name.toLowerCase().includes(lowerCaseValue) || user.email.includes(lowerCaseValue)
			);
		} else {
			return workspaceUsers;
		}
	}, [searchValue, workspaceUsers]);

	const getWorkspaceUsers = async () => {
		setIsLoading(true);
		const result = await axios.get(`${API_URL}/workspaces/${selectedWorkspace._id}/users`);

		setWorkspaceUsers(result.data.users);

		setIsLoading(false);
	};

	useEffect(() => {
		if (isOpen) {
			getWorkspaceUsers();
			setSearchValue('');
		}
	}, [selectedWorkspace, isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent pb={4}>
				<ModalHeader>
					{t('workspaceUsers')}
					<Text fontSize='sm'>{selectedWorkspace.name}</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{isLoading && (
						<Box w='fit-content' mx='auto'>
							<CircularProgress isIndeterminate size='30px' />
						</Box>
					)}
					{!isLoading && (
						<>
							{Boolean(workspaceUsers.length) && (
								<SearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
							)}
							<List mt={2} mb={4} maxHeight={500} overflowY='auto'>
								{filteredUsers.map((user, index) => (
									<WorkspaceUserItem
										user={user}
										workspace={selectedWorkspace}
										key={index}
										onRemove={() =>
											setWorkspaceUsers(workspaceUsers.filter(({ _id }) => _id !== user._id))
										}
									/>
								))}
							</List>

							{!workspaceUsers.length && (
								<Text fontWeight={400} mb={4} fontSize={14}>
									{t('noUsersAddedToWorkspace')}
								</Text>
							)}
						</>
					)}

					{userInfo?.isAdmin && (
						<AddUserPopover
							workspace={selectedWorkspace}
							workspaceUsers={workspaceUsers}
							onAdd={(user) => {
								setWorkspaceUsers([...workspaceUsers, user]);
								setSearchValue('');
							}}
						/>
					)}
				</ModalBody>
				<ModalFooter gap={4}>
					<Button onClick={onClose}>{t('close')}</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default WorkspaceUsersModal;
