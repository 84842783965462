import {
	FormControl,
	FormHelperText,
	FormLabel,
	SystemStyleObject,
	Checkbox,
	Text,
	Box,
	FormErrorMessage,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Setting } from '../data';
import WordsSetting from '../WordsSetting/WordsSetting';
import { Settings } from '../../../redux/slices/general-settings';
import { get } from 'lodash';
import { InputRange } from '../../../Pages/Admin/Settings';

interface Props {
	setting: Setting;
	updatedSettings: Partial<Settings>;
	formControlSx?: SystemStyleObject;
	error?: string;
	range?: InputRange;
	onChange: (path: string, value: any) => void;
}

const SettingInput: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { setting, error } = props;

	const name = useMemo(() => {
		const splitPath = setting.path.split('.');

		return splitPath[splitPath.length - 1];
	}, [setting]);

	return (
		<FormControl isInvalid={Boolean(error)} mb={6}>
			<Input name={name} {...props} />
			{setting.hasHelperText && !error && (
				<FormHelperText fontSize={12} maxWidth='30%'>
					{t(`${name}Helper`)}
				</FormHelperText>
			)}

			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

const Input: React.FC<Props & { name: string }> = (props) => {
	const { t } = useTranslation();
	const { setting, name, updatedSettings, range, onChange } = props;

	const value = useMemo(() => get(updatedSettings, setting.path), [updatedSettings, setting]);

	if (setting.type === 'number') {
		return (
			<>
				<FormLabel fontSize='sm'>{t(name)}</FormLabel>

				{/* <TextInput
					size='sm'
					value={String(value || '')}
					type={setting.type}
					width={100}
					onChange={(e) => onChange(setting.path, Number(e.target.value))}
				/> */}

				<NumberInput
					size='xs'
					value={Number.isNaN(value) ? 0 : value}
					min={range?.min}
					max={range?.max}
					width={100}
					onChange={(value, number) => onChange(setting.path, number ?? '')}>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
			</>
		);
	} else if (setting.type === 'checkbox') {
		return (
			<Checkbox
				isChecked={Boolean(value)}
				onChange={(e) => {
					onChange(setting.path, e.target.checked);
				}}
				colorScheme='blue'>
				<Text fontSize='sm'>{t(name)}</Text>
			</Checkbox>
		);
	} else if (setting.type === 'words') {
		return (
			<Box width='50%'>
				<WordsSetting
					name={name as 'allowWords' | 'denyWords'}
					updatedSettings={updatedSettings}
					onChange={(value) => onChange(setting.path, value)}
				/>
			</Box>
		);
	} else {
		return null;
	}
};

export default SettingInput;
