import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Box,
	Text,
	Icon,
} from '@chakra-ui/react';
import { TFolder } from './Pages';
import { PLACEHOLDER_PAGE_ID, Page, changePagePath } from '../../../../redux/slices/pages';
import PageEntry from './PageEntry';
import {
	CSSProperties,
	Dispatch,
	ReactNode,
	SetStateAction,
	forwardRef,
	useMemo,
	useState,
} from 'react';
import { ConnectDropTarget, useDrop } from 'react-dnd';
import { useAppDispatch } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import FolderMenu from './FolderMenu';
import { AiOutlineFolder } from 'react-icons/ai';

interface Props {
	folder: TFolder;
	expandedFolderPaths: string[];
	style?: CSSProperties;
	setExpandedFolderPaths: Dispatch<SetStateAction<string[]>>;
	onDeleteClick: (page: Page) => void;
	onPageClick: (page: Page) => void;
	onEditClick: (page: Page) => void;
	onAddPageClick: (folder: TFolder) => void;
	onAddFolderClick: (folder: TFolder) => void;
	onFolderDelete: (foler: TFolder) => void;
}

const Folder: React.FC<Props> = (props) => {
	const {
		folder,
		expandedFolderPaths,
		setExpandedFolderPaths,
		onEditClick,
		onDeleteClick,
		onPageClick,
	} = props;
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const [{ isOverCurrent }, drop] = useDrop(
		() => ({
			accept: 'box',
			drop(item: Page, monitor) {
				const didDrop = monitor.didDrop();
				if (monitor.isOver({ shallow: true })) {
					dispatch(changePagePath({ pageId: item._id, updatedPath: folder.path }));
				}

				if (didDrop) {
					return;
				}
			},
			collect: (monitor) => {
				return {
					isOver: monitor.isOver(),
					isOverCurrent: monitor.isOver({ shallow: true }),
				};
			},
		}),
		[]
	);

	return (
		<Container
			{...props}
			ref={drop as any}
			isOverCurrent={isOverCurrent}
			expandedFolderPaths={expandedFolderPaths}
			setExpandedFolderPaths={setExpandedFolderPaths}>
			<>
				{folder.nestedFolders.map((nestedFolder) => (
					<Folder {...props} folder={nestedFolder} key={nestedFolder.path} />
				))}
				{folder.pages.map((page, index) => (
					<PageEntry
						page={page}
						key={page._id}
						index={index}
						onClick={onPageClick}
						onEditClick={onEditClick}
						onDeleteClick={() => onDeleteClick(page)}
					/>
				))}

				{!folder.nestedFolders.length &&
					!folder.pages.filter((page) => page._id !== PLACEHOLDER_PAGE_ID).length && (
						<Text textAlign='center' fontSize='sm' fontWeight={300}>
							{t('emptyFolder')}
						</Text>
					)}
			</>
		</Container>
	);
};

const Container = forwardRef<
	ConnectDropTarget,
	Props & { children: ReactNode; isOverCurrent: boolean }
>((props, ref) => {
	const [isHovered, setIsHovered] = useState(false);
	const {
		children,
		folder,
		isOverCurrent,
		expandedFolderPaths,
		style,
		onFolderDelete,
		onAddPageClick,
		onAddFolderClick,
		setExpandedFolderPaths,
	} = props;

	const isExpanded = useMemo(() => {
		return expandedFolderPaths.includes(folder.path);
	}, [expandedFolderPaths]);

	if (folder.name && folder.path) {
		return (
			<Accordion
				sx={{
					backgroundColor: isOverCurrent ? 'blue.100' : 'gray.100',
					boxShadow: 'sm',
					borderRadius: 8,
					overflow: 'hidden',
				}}
				ref={ref as any}
				key={folder.path}
				marginBottom={2}
				index={isExpanded ? [0] : []}>
				<AccordionItem>
					<h2>
						<AccordionButton
							height='35px'
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							onClick={() => {
								setExpandedFolderPaths(
									isExpanded
										? expandedFolderPaths.filter((path) => path !== folder.path)
										: [...expandedFolderPaths, folder.path]
								);
							}}>
							<Box
								fontSize='sm'
								as='span'
								flex='1'
								textAlign='left'
								display='flex'
								gap={2}
								alignItems='center'>
								<Icon as={AiOutlineFolder} />
								{folder.name}
							</Box>
							<Box onClick={(e) => e.stopPropagation()}>
								<FolderMenu
									isVisible={isHovered}
									folder={folder}
									onDelete={onFolderDelete}
									onAddPageClick={onAddPageClick}
									onAddFolderClick={onAddFolderClick}
								/>
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4} px={2}>
						{children}
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		);
	} else {
		return (
			<Box
				key={folder.path}
				sx={{ backgroundColor: isOverCurrent ? 'blue.100' : '' }}
				style={style}
				ref={ref as any}>
				{children}
			</Box>
		);
	}
});

export default Folder;
