import { Box, Icon, IconButton, Tag, TagLabel, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { Entry, deleteEntry } from '../../../redux/slices/entries';
import { FiEdit2 } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import { useState, useEffect } from 'react';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';
import UserPopover from '../../Popovers/UserPopover';
interface Props {
	entry: Entry;
	isHovered: boolean;
	onEditClick: () => void;
}

const EntryItemViewMode: React.FC<Props> = (props) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [isCreatedByUserPopoverOpen, setIsCreatedByUserPopoverOpen] = useState(false);
	const [isUpdatedByUserPopoverOpen, setIsUpdatedByUserPopoverOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
	const { entry, isHovered, onEditClick } = props;

	const handleDelete = async () => {
		setIsDeleteLoading(true);
		await dispatch(deleteEntry(entry?._id!));
		setIsDeleteLoading(false);
	};

	useEffect(() => {
		if (!isHovered) {
			setIsCreatedByUserPopoverOpen(false);
			setIsUpdatedByUserPopoverOpen(false);
		}
	}, [isHovered]);

	console.log(entry)
	return (
		<Box>
			<ConfirmDialog
				isOpen={deleteConfirmDialog}
				title={t('deleteEntry')}
				isActionLoading={isDeleteLoading}
				actionButtonContent={t('delete')}
				onClose={() => setDeleteConfirmDialog(false)}
				onAction={handleDelete}
			/>

			{isHovered && (
				<Box minHeight={35} display='flex' alignItems='center'>
					{/* TODO: localize */}
					<Box ml={2} mt={1} mb={2}>
						<UserPopover
							anchor={
								<Text fontWeight={400} color='GrayText' fontSize='xs'>
									Created{' '}
									<Tooltip label={moment(entry?.createdAt).format('hh:mm A, DD MMM YYYY')}>
										<b style={{ cursor: 'pointer' }}>{moment(entry?.createdAt).fromNow()}</b>
									</Tooltip>{' '}
									by{' '}
									<Box
										onClick={() => setIsCreatedByUserPopoverOpen(true)}
										_hover={{ cursor: 'pointer', textDecoration: 'underline' }}
										as={'b'}>
										{entry.createdBy?.name}
									</Box>
								</Text>
							}
							user={entry.createdBy}
							isOpen={isCreatedByUserPopoverOpen}
							onOpen={() => setIsCreatedByUserPopoverOpen(true)}
							onClose={() => setIsCreatedByUserPopoverOpen(false)}
						/>

						{entry.updatedAt && entry.updatedBy && (
							<UserPopover
								anchor={
									<Text color='GrayText' fontWeight={400} fontSize='xs'>
										Last updated{' '}
										<Tooltip label={moment(entry?.updatedAt).format('hh:mm A, DD MMM YYYY')}>
											<b style={{ cursor: 'pointer' }}>{moment(entry?.updatedAt).fromNow()}</b>
										</Tooltip>{' '}
										by{' '}
										<Box _hover={{ cursor: 'pointer', textDecoration: 'underline' }} as={'b'}>
											{entry.updatedBy?.name}
										</Box>
									</Text>
								}
								user={entry.updatedBy}
								isOpen={isUpdatedByUserPopoverOpen}
								onOpen={() => setIsUpdatedByUserPopoverOpen(true)}
								onClose={() => setIsUpdatedByUserPopoverOpen(false)}
							/>
						)}
					</Box>
					<Box gap={2} display='flex' position='absolute' right={4}>
						{entry.tags?.map((tag) => (
							<Tag size='sm' key={tag}>
								<TagLabel>{tag}</TagLabel>
							</Tag>
						))}
						<Tooltip label='Edit'>
							<IconButton
								ml={4}
								variant='outline'
								colorScheme='teal'
								aria-label='Edit'
								size='sm'
								fontSize='16px'
								onClick={onEditClick}
								icon={<Icon as={FiEdit2} />}
							/>
						</Tooltip>
						<Tooltip label='Delete'>
							<IconButton
								variant='outline'
								colorScheme='teal'
								aria-label='Delete'
								size='sm'
								fontSize='16px'
								onClick={() => setDeleteConfirmDialog(true)}
								icon={<Icon as={AiOutlineDelete} />}
							/>
						</Tooltip>
					</Box>
				</Box>
			)}

			<RichTextEditor readOnly defaultValue={entry?.richContent} />
		</Box>
	);
};

export default EntryItemViewMode;
