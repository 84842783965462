import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../config';
import { createStandaloneToast } from '@chakra-ui/react';
import i18n from '../../i18n';

const { toast } = createStandaloneToast();

export type Settings = {
	chatGpt?: {
		maxNoOfMessagesPerConversation?: number;
	};
	aiSearchFilter?: {
		allowWords?: FilterWord[];
		denyWords?: FilterWord[];
		minAllowWordsToAcceptQuery: number;
		minDenyWordsToDenyQuery: number;
	};
	user?: {
		maxNoOfWorkspacesForUser?: number;
	};
	application?: {
		showMostRecentEntriesOnHomePage?: boolean;
	};
};

export type Subscription = {
	id: string;
	label: string;
	limits: {
		chatGptTokens: number;
		workspaces: number;
		users: number;
		allowWords: number;
		denyWords: number;
	};
};

type SubscriptionUsage = {
	workspaces?: number;
	users?: number;
	chatGptTokens?: number;
};

export type FilterWord = {
	word: string;
	synonyms: string[];
};

type GeneralSettingsState = {
	isLoading: boolean;
	settings: Settings | null;
	subscription: Subscription | null;
	usages: SubscriptionUsage | null;
};

const initialState: GeneralSettingsState = {
	isLoading: false,
	settings: null,
	subscription: null,
	usages: null,
};

export const getSettings = createAsyncThunk('general-settings/get', async () => {
	const result = await axios.get(`${API_URL}/settings`);

	return result.data;
});

export const updateSettings = createAsyncThunk(
	'general-settings/update',
	async (updates: { [path: string]: any }) => {
		const result = await axios.put(`${API_URL}/settings`, updates);

		return result.data.settings;
	}
);

export const generalSettingsSlice = createSlice({
	name: 'general-settings',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getSettings.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getSettings.fulfilled, (state, action) => {
			state.settings = action.payload.settings;
			state.subscription = action.payload.subscription;
			state.usages = action.payload.usages;
			state.isLoading = false;
		});

		builder.addCase(getSettings.rejected, () => {
			toast({
				title: i18n.t('common:loadSettingsError'),
				status: 'error',
			});
		});

		builder.addCase(updateSettings.fulfilled, (state, action) => {
			state.settings = action.payload;
			toast({
				title: i18n.t('common:updateSettingsSuccess'),
				status: 'success',
			});
		});

		builder.addCase(updateSettings.rejected, () => {
			toast({
				title: i18n.t('common:updateSettingsError'),
				status: 'error',
			});
		});
	},
});
