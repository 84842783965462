import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Input,
	ModalFooter,
	Button,
	Icon,
	FormErrorMessage,
	FormControl,
	FormLabel,
} from '@chakra-ui/react';
import {
	Workspace,
	createWorkspace,
	deleteWorkspace,
	updateWorkspace,
} from '../../redux/slices/workspaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/store';
import { AiOutlineDelete, AiOutlineSave } from 'react-icons/ai';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

interface Props {
	isOpen: boolean;
	selectedWorkspace?: Workspace | null;
	defaultInputValues?: Partial<Workspace>;
	onClose: () => void;
}

const WorkspaceModal: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [inputValues, setInputValues] = useState<Partial<Workspace>>({});
	const [errors, setErrors] = useState<Record<string, string>>({});
	const { isOpen, selectedWorkspace, defaultInputValues, onClose } = props;
	const dispatch = useAppDispatch();

	const handleSave = async () => {
		if (!inputValues.name) {
			setErrors({ ...errors, name: t('required') });

			return;
		}

		setIsSaveLoading(true);
		setErrors({});

		if (selectedWorkspace) {
			await dispatch(updateWorkspace({ inputValues, workspaceId: selectedWorkspace._id }));
		} else {
			await dispatch(createWorkspace(inputValues));
		}

		setIsSaveLoading(false);
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			if (selectedWorkspace) {
				setInputValues({ ...selectedWorkspace });
			} else {
				setInputValues({ ...defaultInputValues });
			}
			setErrors({});
		}
	}, [selectedWorkspace, defaultInputValues, isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ConfirmDialog
				title={t('deleteWorkspaceConfirmTitle')}
				body={t('deleteWorkspaceConfirmContent')}
				actionButtonContent={t('delete')}
				isOpen={isDeleteConfirmDialogOpen}
				onClose={() => setIsDeleteConfirmDialogOpen(false)}
				onAction={async () => {
					setIsDeleteConfirmDialogOpen(false);
					setIsDeleteLoading(true);
					await dispatch(deleteWorkspace(selectedWorkspace!._id));
					setIsDeleteLoading(false);
					onClose();
				}}
			/>
			<ModalOverlay />
			<ModalContent pb={4}>
				<ModalHeader>{selectedWorkspace ? t('editWorkspace') : t('createWorkspace')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl isInvalid={Boolean(errors.name)}>
						<FormLabel>{t('name')}</FormLabel>
						<Input
							value={inputValues.name || ''}
							onChange={(e) => setInputValues({ ...inputValues, name: e.target.value })}
						/>

						<FormErrorMessage>{errors.name}</FormErrorMessage>
					</FormControl>
				</ModalBody>
				<ModalFooter gap={4}>
					<Button onClick={onClose}>{t('close')}</Button>
					{selectedWorkspace && (
						<Button
							leftIcon={<Icon as={AiOutlineDelete} />}
							isLoading={isDeleteLoading}
							colorScheme='red'
							onClick={() => setIsDeleteConfirmDialogOpen(true)}>
							{t('delete')}
						</Button>
					)}

					<Button
						leftIcon={<Icon as={AiOutlineSave} />}
						isLoading={isSaveLoading}
						colorScheme='blue'
						onClick={handleSave}>
						{t('save')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default WorkspaceModal;
