import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import RecentSearches from './RecentSearches';
import Pages from './Pages/Pages';
import { useTranslation } from 'react-i18next';

const SearchSidebar = () => {
	const { t } = useTranslation();
	return (
		<Box width='60%' py={3}>
			{/* <Text mt={1.5} fontWeight={700} fontSize='md'>
				Searches
			</Text> */}

			<Tabs size='sm'>
				<TabList>
					<Tab>{t('pages')}</Tab>
					<Tab>{t('recentSearches')}</Tab>
				</TabList>
				<TabPanels overflowY='auto' height="calc(100vh - 60px)">
					<TabPanel>
						<Pages />
					</TabPanel>
					<TabPanel p={1}>
						<RecentSearches />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};

export default SearchSidebar;
