import PageContainer from '../../Components/PageContainer';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Button,
	CircularProgress,
	Heading,
	Icon,
	IconButton,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
} from '@chakra-ui/react';
import { BsPeople } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { StoreState } from '../../redux/store';
import { useMemo, useState } from 'react';
import WorkspaceModal from '../../Components/Modals/WorkspaceModal';
import WorkspaceUsersModal from '../../Components/Modals/WorkspaceUsersModal/WorkspaceUsersModal';
import { Workspace } from '../../redux/slices/workspaces';
import { FiEdit } from 'react-icons/fi';
import SearchInput from '../../Components/Inputs/SearchInput';

const AdminWorkspacesPage = () => {
	const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false);
	const [isWorkspaceUsersModalOpen, setIsWorkspaceUsersModalOpen] = useState(false);
	const [clickedWorkspace, setClickedWorkspace] = useState<Workspace | null>(null);
	const [searchValue, setSearchValue] = useState('');
	const { workspaces, isWorkspacesLoading } = useSelector((state: StoreState) => state.workspaces);
	const subscription = useSelector((state: StoreState) => state.generalSettings.subscription);
	const { t } = useTranslation();

	const isWorkspacesLimitReached = useMemo(
		() => workspaces.length >= (subscription?.limits?.workspaces || 0),
		[subscription, workspaces]
	);

	const filteredWorkspaces = useMemo(() => {
		if (searchValue) {
			const lowerCaseSearchValue = searchValue.toLowerCase();

			return workspaces.filter((workspace) =>
				workspace.name.toLowerCase().includes(lowerCaseSearchValue)
			);
		} else {
			return workspaces;
		}
	}, [workspaces, searchValue]);

	return (
		<PageContainer>
			<Box mt={4} display='flex' alignItems='center'>
				<WorkspaceModal
					selectedWorkspace={clickedWorkspace}
					isOpen={isWorkspaceModalOpen}
					onClose={() => setIsWorkspaceModalOpen(false)}
				/>

				{clickedWorkspace && (
					<WorkspaceUsersModal
						selectedWorkspace={clickedWorkspace}
						isOpen={isWorkspaceUsersModalOpen}
						onClose={() => setIsWorkspaceUsersModalOpen(false)}
					/>
				)}

				<Icon mr={3} fontSize='2xl' as={BsPeople} />
				<Heading fontSize='2xl'>{t('workspaces')}</Heading>

				<Box display='flex' gap={4} alignItems='center' width='fit-content' ml='auto'>
					<Box width={200}>
						<SearchInput
							inputSx={{ backgroundColor: 'white' }}
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
					</Box>

					<Tooltip label={isWorkspacesLimitReached ? t('workspacesLimitReached') : ''}>
						<Button
							size='sm'
							onClick={() => {
								setIsWorkspaceModalOpen(true);
								setClickedWorkspace(null);
							}}
							isDisabled={isWorkspacesLimitReached}
							colorScheme='blue'>
							{t('addWorkspace')}
						</Button>
					</Tooltip>
				</Box>
			</Box>

			{isWorkspacesLoading && (
				<Box w='fit-content' mx='auto'>
					<CircularProgress isIndeterminate size='30px' />
				</Box>
			)}

			{!isWorkspacesLoading && (
				<TableContainer mt={6} backgroundColor='white' borderRadius={4}>
					<Table variant='simple'>
						<Thead>
							<Tr>
								<Th>{t('name')}</Th>
								<Th display='flex' alignItems='center' justifyContent='center'>
									{t('actions')}
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{filteredWorkspaces.map((workspace, index) => (
								<Tr key={index}>
									<Td>{workspace.name}</Td>
									<Td gap={4} display='flex' alignItems='center' justifyContent='center'>
										<Tooltip label={t('editWorkspace')}>
											<IconButton
												size='sm'
												aria-label='edit-workspace'
												onClick={(e) => {
													e.stopPropagation();
													setClickedWorkspace(workspace);
													setIsWorkspaceModalOpen(true);
												}}>
												<FiEdit />
											</IconButton>
										</Tooltip>

										<Tooltip label={t('workspaceUsers')}>
											<IconButton
												size='sm'
												aria-label='edit-workspace-users'
												onClick={(e) => {
													e.stopPropagation();
													setClickedWorkspace(workspace);
													setIsWorkspaceUsersModalOpen(true);
												}}>
												<BsPeople />
											</IconButton>
										</Tooltip>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					{!filteredWorkspaces.length && !isWorkspacesLoading && (
						<Text p={4} textAlign='center'>
							{t('noWorkspacesFound')}
						</Text>
					)}
				</TableContainer>
			)}
		</PageContainer>
	);
};

export default AdminWorkspacesPage;
