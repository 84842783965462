import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Tag,
	TagCloseButton,
	TagLabel,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { removeSpacesFromString } from '../../../helpers';
import { FilterWord, Settings } from '../../../redux/slices/general-settings';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../redux/store';
import WordsModal from './WordsModal';

interface Props {
	name: 'allowWords' | 'denyWords';
	updatedSettings: Partial<Settings>;
	onChange: (value: FilterWord[]) => void;
}

const WordsSetting: React.FC<Props> = ({ updatedSettings, name, onChange }) => {
	const { t } = useTranslation();
	const subscription = useSelector((state: StoreState) => state.generalSettings.subscription);
	const [newWordError, setNewWordError] = useState('');
	const [newWordInputValue, setNewWordInputValue] = useState('');
	const [isWordsModalOpen, setIsWordsModalOpen] = useState(false);
	const value = useMemo(() => updatedSettings.aiSearchFilter?.[name] || [], [updatedSettings]);

	const isWordsLimitReached = useMemo(() => {
		const limit = subscription?.limits?.[name] || 0;

		return value.length >= limit;
	}, [name, value, subscription]);

	const handleAddNewWord = () => {
		if (!newWordInputValue || newWordError) {
			return;
		}

		if (!value.find((word) => word.word === newWordInputValue)) {
			onChange([...value, { word: removeSpacesFromString(newWordInputValue), synonyms: [] }]);
		}

		setNewWordInputValue('');
	};

	const handleNewWordInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newWord = e.target.value.toLowerCase();

		setNewWordInputValue(newWord);

		const oppositeWords =
			name === 'allowWords'
				? updatedSettings.aiSearchFilter?.denyWords
				: updatedSettings.aiSearchFilter?.allowWords;

		if (oppositeWords?.find(({ word }) => word === newWord)) {
			setNewWordError(
				t(
					name === 'allowWords' ? 'wordIsAlreadyAddedToDeny' : 'wordIsAlreadyAddedToAllow'
				) as string
			);
		} else if (value.find(({ word }) => word === newWord)) {
			setNewWordError(t('wordIsAlreadyAdded') as string);
		} else {
			setNewWordError('');
		}
	};

	return (
		<Box>
			<WordsModal
				isOpen={isWordsModalOpen}
				onClose={() => setIsWordsModalOpen(false)}
				name={name}
				words={value}
			/>
			<Box display='flex' alignItems='center' gap={2} mb={2}>
				<FormLabel m={0} fontSize='sm'>
					{t(name)}
				</FormLabel>

				{Boolean(value?.length) && (
					<Button colorScheme='blue' onClick={() => setIsWordsModalOpen(true)} ml={2} size='xs'>
						{t('viewAll')}
					</Button>
				)}
			</Box>
			<Box mb={3} display='flex' flexWrap='wrap' gap={2}>
				{value?.map((word, index) => (
					<WordEntry
						key={index}
						word={word}
						onDelete={() => onChange(value.filter((wordEntry) => word.word !== wordEntry.word))}
					/>
				))}
			</Box>
			<Tooltip label={isWordsLimitReached && t('wordsLimitReached')}>
				<Box gap={2} display='flex'>
					<FormControl isInvalid={Boolean(newWordError)} width={150}>
						<Input
							size='xs'
							isDisabled={isWordsLimitReached}
							placeholder={t('enterWord') as string}
							value={newWordInputValue}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleAddNewWord();
								} else if (e.key === ' ') {
									console.log('prevented');
									e.preventDefault();
								}
							}}
							onChange={handleNewWordInputChange}
						/>

						<FormErrorMessage fontSize={12}>{newWordError}</FormErrorMessage>
					</FormControl>

					<Button
						isDisabled={isWordsLimitReached || Boolean(newWordError)}
						onClick={handleAddNewWord}
						size='sm'
						colorScheme='teal'
						variant='ghost'>
						{t('addWord')}
					</Button>
				</Box>
			</Tooltip>
		</Box>
	);
};

const WordEntry: React.FC<{ word: FilterWord; onDelete: () => void }> = ({ word, onDelete }) => {
	return (
		<Tooltip
			label={
				Boolean(word.synonyms.length) && (
					<>
						<Text>Synonyms</Text>
						<Box mt={2} mb={2} display='flex' gap={2} flexWrap='wrap'>
							{word.synonyms.map((synonym) => (
								<Tag key={synonym} size='sm'>
									{synonym}
								</Tag>
							))}
						</Box>
					</>
				)
			}>
			<Tag size='sm' cursor='pointer' key={word.word}>
				<TagLabel>{word.word}</TagLabel>
				<TagCloseButton onClick={onDelete} />
			</Tag>
		</Tooltip>
	);
};

export default WordsSetting;
