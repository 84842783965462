import PageContainer from '../../Components/PageContainer';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Heading, Icon, Text } from '@chakra-ui/react';
import { FiSettings } from 'react-icons/fi';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Settings, updateSettings } from '../../redux/slices/general-settings';
import { cloneDeep, get, set, sum } from 'lodash';
import { AiOutlineSave } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { StoreState, useAppDispatch } from '../../redux/store';
import { unstable_usePrompt } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { CiMoneyCheck1 } from 'react-icons/ci';
import SubscriptionInfo from '../../Components/Settings/Sections/SubscriptionInfo';
import { getSettingsSections } from '../../Components/Settings/data';
import SettingsSection from '../../Components/Settings/SettingsSection';
import SettingInput from '../../Components/Settings/Sections/SettingInput';
import SearchInput from '../../Components/Inputs/SearchInput';

export type SettingChangeHandler = (path: string, value: any) => void;
export type InputRange = {
	min: number;
	max: number;
};

const AdminSettingsPage = () => {
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [updatedSettings, setUpdatedSettings] = useState<Partial<Settings>>({});
	const [searchValue, setSearchValue] = useState('');
	const { isLoading, settings } = useSelector((state: StoreState) => state.generalSettings);
	const updatedSettingPaths = useRef<{ [path: string]: any }>({});
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	useBeforeunload(hasUnsavedChanges ? (event) => event.preventDefault() : undefined);

	unstable_usePrompt({
		message: t('unsavedChangesWarning'),
		when: hasUnsavedChanges,
	});

	const inputRanges = useMemo(() => {
		const allowWords = get(updatedSettings, 'aiSearchFilter.allowWords') || [];
		const denyWords = get(updatedSettings, 'aiSearchFilter.denyWords') || [];

		let ranges: Record<string, InputRange> = {
			'aiSearchFilter.minAllowWordsToAcceptQuery': {
				min: 0,
				max: allowWords.length + sum(allowWords?.map((word) => word.synonyms.length)),
			},
			'aiSearchFilter.minDenyWordsToDenyQuery': {
				min: 0,
				max: denyWords.length + sum(denyWords?.map((word) => word.synonyms.length)),
			},
			'chatGpt.maxNoOfMessagesPerConversation': {
				min: 1,
				max: 999,
			},
			'user.maxNoOfWorkspacesForUser': {
				min: 1,
				max: 999,
			},
		};

		return ranges;
	}, [updatedSettings]);

	const allSections = useMemo(() => getSettingsSections(t), []);

	const sections = useMemo(() => {
		if (searchValue) {
			const lowerCaseSearchValue = searchValue.toLowerCase();

			// Filter section settings
			let sections = allSections.map((section) => {
				return {
					...section,
					settings: section.settings.filter((setting) =>
						setting.name.toLowerCase().includes(lowerCaseSearchValue)
					),
				};
			});

			// Show only sections that have settings remaining in them
			return sections.filter((section) => section.settings.length);
		} else {
			return allSections;
		}
	}, [allSections, searchValue]);

	const handleSettingChange: SettingChangeHandler = (path, value) => {
		const range = inputRanges[path];
		if (range && typeof value === 'number' && value > range.max) {
			value = range.max;
		}

		updatedSettingPaths.current[path] = value;
		let updatedInputValues = cloneDeep(updatedSettings);
		setUpdatedSettings({ ...set(updatedInputValues, path, value) });
		setHasUnsavedChanges(true);
	};

	const handleSave = async () => {
		if (!hasUnsavedChanges) {
			return;
		}

		setIsSaveLoading(true);
		await dispatch(updateSettings(updatedSettingPaths.current));

		updatedSettingPaths.current = {};

		setHasUnsavedChanges(false);

		setIsSaveLoading(false);
	};

	useEffect(() => {
		if (settings) {
			setUpdatedSettings(settings);
			updatedSettingPaths.current = {};
		}
	}, [settings]);

	return (
		<PageContainer>
			{isLoading ? (
				<Box w='fit-content' mx='auto' mt={6}>
					<CircularProgress isIndeterminate />
				</Box>
			) : (
				<>
					<Box mt={4} display='flex' alignItems='center'>
						<Icon mr={3} fontSize='2xl' as={CiMoneyCheck1} />
						<Heading fontSize='2xl'>{t('subscription')}</Heading>
					</Box>

					<SubscriptionInfo />

					<Box mt={8} display='flex' alignItems='center'>
						<Icon mr={3} fontSize='2xl' as={FiSettings} />
						<Heading fontSize='2xl'>{t('settings')}</Heading>

						<Box w='fit-content' ml='auto'>
							<SearchInput
								placeholder={t('searchSettings') as string}
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
								onClearClick={() => setSearchValue('')}
							/>
						</Box>
					</Box>

					{sections.map((section) => (
						<SettingsSection key={section.title} title={section.title} Icon={section.Icon}>
							{section.settings.map((setting) => (
								<SettingInput
									key={setting.path}
									setting={setting}
									range={inputRanges[setting.path]}
									updatedSettings={updatedSettings}
									onChange={handleSettingChange}
								/>
							))}
						</SettingsSection>
					))}

					{sections.length === 0 && (
						<Text my={6} fontSize={14} textAlign='center'>
							{t('noSettingsFound')}
						</Text>
					)}

					<Box width='fit-content' ml='auto' mt={6}>
						<Button
							colorScheme='blue'
							leftIcon={<Icon as={AiOutlineSave} />}
							onClick={handleSave}
							isLoading={isSaveLoading}
							isDisabled={!hasUnsavedChanges}>
							{t('saveChanges')}
						</Button>
					</Box>
				</>
			)}
		</PageContainer>
	);
};

export default AdminSettingsPage;
