import {
	HStack,
	Menu,
	MenuButton,
	Avatar,
	VStack,
	MenuList,
	MenuItem,
	Text,
	Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StoreState, useAppDispatch } from '../../redux/store';
import { signOut } from '../../redux/slices/current-user';
import { LOGIN_PAGE_PATH } from '../../App';
import { useNavigate } from 'react-router';
import { FiChevronDown } from 'react-icons/fi';

interface Props {}

const UserMenu: React.FC<Props> = ({}) => {
	const { t } = useTranslation();
	const userInfo = useSelector((state: StoreState) => state.currentUser.userInfo);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handleSignOutClick = () => {
		dispatch(signOut());
		navigate(LOGIN_PAGE_PATH);
	};

	return (
		<Menu>
			{(menuState) => (
				<>
					{userInfo && (
						<MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
							<HStack>
								<Avatar size={'sm'} />
								<VStack
									display={{ base: 'none', md: 'flex' }}
									alignItems='flex-start'
									spacing='1px'
									ml='2'>
									<Text fontSize='sm'>
										{userInfo.given_name} {userInfo.family_name}
									</Text>
								</VStack>
								<Box display={{ base: 'none', md: 'flex' }}>
									<FiChevronDown />
								</Box>
							</HStack>
						</MenuButton>
					)}
					<MenuList bg='white' borderColor='gray.200'>
						<MenuItem onClick={handleSignOutClick}>{t('signOut')}</MenuItem>
					</MenuList>
				</>
			)}
		</Menu>
	);
};

export default UserMenu;
