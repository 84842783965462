import { Flex, FlexProps, Icon, Link } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IconType } from 'react-icons/lib';
import { useLocation, useNavigate } from 'react-router';

interface NavItemProps extends FlexProps {
	icon?: IconType;
	children: ReactNode;
	path?: string;
	lightSelection?: boolean;
	isSelected?: boolean;
}

const NavItem = ({
	children,
	icon,
	path,
	isSelected,
	onClick,
	lightSelection,
	...rest
}: NavItemProps) => {
	const navigate = useNavigate();
	const location = useLocation();

	isSelected = isSelected || location.pathname === path;

	return (
		<Link
			href='#'
			onClick={(e) => {
				e.preventDefault();
				onClick?.(e as any);
				if (path) {
					navigate(path);
				}
			}}
			style={{ textDecoration: 'none' }}
			_focus={{ boxShadow: 'none' }}>
			<Flex
				align='center'
				borderRadius='lg'
				px={2}
				mx={3}
				py={1.5}
				mt={2}
				fontSize={14}
				role='group'
				cursor='pointer'
				color={isSelected ? 'white' : ''}
				backgroundColor={isSelected ? (lightSelection ? 'gray.600' : 'gray.700') : ''}
				_hover={
					!isSelected
						? {
								bg: 'gray.200',
								color: 'black',
						  }
						: undefined
				}
				{...rest}>
				{icon && (
					<Icon
						mr='4'
						fontSize='16'
						_groupHover={
							!isSelected
								? {
										color: 'black',
								  }
								: undefined
						}
						as={icon}
					/>
				)}
				{children}
			</Flex>
		</Link>
	);
};

export default NavItem;
