import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';
import React, { ReactNode, useRef } from 'react';

interface Props {
	isOpen: boolean;
	title: string;
	body?: ReactNode;
	actionButtonContent: ReactNode;
	isActionLoading?: boolean;
	onAction: () => void;
	onClose: () => void;
}

const ConfirmDialog: React.FC<Props> = (props) => {
	const cancelRef = useRef<any>();
	const { isOpen, title, body, actionButtonContent, isActionLoading, onAction, onClose } = props;

	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						{title}
					</AlertDialogHeader>

					<AlertDialogBody>
						{body || "Are you sure? You can't undo this action afterwards."}
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancel
						</Button>
						<Button isLoading={isActionLoading} colorScheme='red' onClick={onAction} ml={3}>
							{actionButtonContent}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default ConfirmDialog;
