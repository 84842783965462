import { Box, Divider, IconButton, Tag, TagLabel, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { SearchValue } from '../../../redux/slices/entries-search';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../redux/store';
import moment from 'moment';

interface Props {
	onClick: (search: SearchValue) => void;
	onDeleteClick: (search: SearchValue) => void;
	searchEntry: SearchValue;
}

const SearchEntry: React.FC<Props> = ({ onClick, onDeleteClick, searchEntry }) => {
	const [isHovered, setIsHovered] = useState(false);
	const { searchValue } = useSelector((state: StoreState) => state.entriesSearch);

	const isSelected = useMemo(() => searchValue._id === searchEntry._id, [searchValue, searchEntry]);
	
	return (
		<>
			<Box
				p={3}
				cursor='pointer'
				borderRadius={4}
				color={isSelected ? 'white' : ''}
				bgColor={isSelected ? 'gray.700' : ''}
				my={1}
				minHeight='50px'
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				onClick={() => onClick(searchEntry)}
				display='flex'>
				<Box width='90%'>
					{searchEntry.text && <Text fontSize='sm'>{searchEntry.text}</Text>}
					{Boolean(searchEntry.tags.length) && (
						<Box display='flex' gap={2} mt={searchEntry.text ? 2 : 0}>
							{searchEntry.tags?.map((tag, index) => (
								<Tag size='sm' key={index}>
									<TagLabel>{tag}</TagLabel>
								</Tag>
							))}
						</Box>
					)}

					<Text fontSize='xs' color={isSelected ? 'gray.100' : 'GrayText'} mt={1}>
						{moment(searchEntry.createdAt).fromNow()}
					</Text>
				</Box>
				{isHovered && (
					<IconButton
						size='xs'
						color='black'
						aria-label='delete-icon-button'
						onClick={(e) => {
							e.stopPropagation();

							onDeleteClick(searchEntry);
						}}>
						<AiOutlineDelete />
					</IconButton>
				)}
			</Box>
			<Divider />
		</>
	);
};

export default SearchEntry;
