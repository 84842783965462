import { Box, Button, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import SuggestionsInput from './SuggestionsInput/SuggestionsInput';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../redux/store';
import { useTranslation } from 'react-i18next';

interface Props {
	value: string[];
	onChange: (value: string[]) => void;
}

const TagsInput: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const [tagNameInputValue, setTagNameInputValue] = useState('');
	const allTags = useSelector((state: StoreState) => state.entries.tags);

	const { value, onChange } = props;

	const suggestedTags = useMemo(
		() => allTags.filter((tag) => !value?.includes(tag)),
		[value, allTags]
	);

	const handleAddClick = () => {
		if (tagNameInputValue) {
			if (!value.includes(tagNameInputValue)) {
				onChange([tagNameInputValue, ...(value || [])]);
			}
			setTagNameInputValue('');
		}
	};

	return (
		<Box>
			<Box gap={2} alignItems='center' display='flex'>
				<SuggestionsInput
					suggestions={suggestedTags.map((tag) => ({ value: tag }))}
					onChange={(e) => setTagNameInputValue(e.target.value)}
					placeholder='Tag Name'
					onEnter={() => handleAddClick()}
					sx={{ width: 200 }}
					value={tagNameInputValue}
				/>

				<Button onClick={handleAddClick} size='sm' colorScheme='teal' variant='ghost'>
					{t('addTag')}
				</Button>
			</Box>

			<Box mt={4} display='flex' flexWrap='wrap' gap={2}>
				{value?.map((tag) => (
					<Tag key={tag}>
						<TagLabel>{tag}</TagLabel>
						<TagCloseButton onClick={() => onChange(value.filter((name) => tag !== name))} />
					</Tag>
				))}
			</Box>
		</Box>
	);
};

export default TagsInput;
