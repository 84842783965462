import { createSlice } from '@reduxjs/toolkit';
import uniqid from 'uniqid';

export type SearchValue = {
	_id: string;
	text: string;
	tags: string[];
	createdAt: string;
	isSavedSearch?: boolean;
};

type EntriesSearchSliceState = {
	searchValue: SearchValue;
	isSavedSearchesLoading: boolean;
	savedSearches: SearchValue[];
	recentSearches: SearchValue[];
};

export const RECENT_SEARCHES_LS_KEY = 'RECENT_SEARCHES_LS_KEY';

const defaultSearchValue: SearchValue = {
	_id: '',
	text: '',
	tags: [],
	createdAt: '',
};

const initialState: EntriesSearchSliceState = {
	savedSearches: [],
	isSavedSearchesLoading: false,
	recentSearches: JSON.parse(localStorage.getItem(RECENT_SEARCHES_LS_KEY) || '[]'),

	searchValue: defaultSearchValue,
};

export const entriesSearchSlice = createSlice({
	name: 'entriesSearch',
	initialState,
	reducers: {
		setSearchValue: (state, action) => {
			state.searchValue = action.payload;
		},

		updateRecentSearches: (state, action) => {
			state.recentSearches = action.payload;
			localStorage.setItem(RECENT_SEARCHES_LS_KEY, JSON.stringify(action.payload));
		},

		clearSearchValue: (state) => {
			state.searchValue = defaultSearchValue;
		},

		saveRecentSearch: (state, action: { payload: Partial<SearchValue> }) => {
			if ((!action.payload.text && !action.payload.tags?.length) || action.payload.isSavedSearch) {
				return;
			}

			const isSearchWithSameValueSaved = state.recentSearches.find(
				(search) =>
					search.text === action.payload.text &&
					search.tags.every((tag) => action.payload.tags?.includes(tag))
			);

			if (!isSearchWithSameValueSaved) {
				const updatedRecentSearches: SearchValue[] = [
					{ ...action.payload, _id: uniqid(), createdAt: new Date().toISOString() } as SearchValue,
					...state.recentSearches,
				];

				if (updatedRecentSearches.length > MAX_RECENT_SEARCHES_STORED) {
					updatedRecentSearches.pop();
				}

				state.recentSearches = updatedRecentSearches;

				localStorage.setItem(RECENT_SEARCHES_LS_KEY, JSON.stringify(updatedRecentSearches));
			}
		},
	},
});

export const { setSearchValue, updateRecentSearches, clearSearchValue, saveRecentSearch } =
	entriesSearchSlice.actions;

const MAX_RECENT_SEARCHES_STORED = 30;
