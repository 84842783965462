import { Box, Button, Card, CardBody, Tag, TagLabel, Text, Tooltip } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { StoreState, useAppDispatch } from '../../redux/store';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchEntries } from '../../redux/slices/entries';
import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineSave } from 'react-icons/ai';
import chatGptLogo from '../../images/chat-gpt-log.png';
import {
	AiConversationMessage,
	addAiConversationMessage,
	sendMessagesToAi,
} from '../../redux/slices/ai-search';
import PageModal from '../Modals/PageModal';
import { setSearchValue } from '../../redux/slices/entries-search';
import SearchInput from '../Inputs/SearchInput';

const ResultSummary = () => {
	const [isSearchSaved, setIsSearchSaved] = useState(false);
	const [isPageModalOpen, setIsPageModalOpen] = useState(false);
	const [searchInputValue, setSearchInputValue] = useState('');
	const tags = useSelector((state: StoreState) => state.entries.tags);
	const isEntriesLoading = useSelector((state: StoreState) => state.entries.isEntriesLoading);
	const searchValue = useSelector((state: StoreState) => state.entriesSearch.searchValue);
	const aiConversationMessages = useSelector(
		(state: StoreState) => state.aiSearch.aiConversationMessages
	);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const filteredTags = useMemo(() => {
		if (searchInputValue) {
			const lowerCaseSearchValue = searchInputValue.toLowerCase();

			return tags.filter((tag) => tag.includes(lowerCaseSearchValue));
		} else {
			return tags;
		}
	}, [tags, searchInputValue]);

	const hasSearchValue = useMemo(
		() => Boolean(searchValue.tags.length) || Boolean(searchValue.text),
		[searchValue]
	);

	const handleShowChatGptResultsClick = () => {
		const message: AiConversationMessage = {
			role: 'user',
			content: searchValue.text,
		};

		dispatch(addAiConversationMessage(message));
		dispatch(sendMessagesToAi([message]));
	};

	const handleTagClick = (tag: string) => {
		const searchValue = { text: '', tags: [tag] };

		dispatch(setSearchValue(searchValue));
		dispatch(fetchEntries(searchValue));
	};

	useEffect(() => {
		setIsSearchSaved(false);
		setSearchInputValue('');
	}, [searchValue]);

	return (
		<Card mb={4}>
			<PageModal
				isOpen={isPageModalOpen}
				defaultInputValues={{
					searchText: searchValue.text,
					tags: searchValue.tags,
				}}
				onClose={() => setIsPageModalOpen(false)}
			/>

			<CardBody>
				<Box display='flex' alignItems='center'>
					<Box display='flex' alignItems='center' w='100%'>
						<Text size='md'>
							{hasSearchValue
								? `${t('showingResultsFound')}: ${searchValue.text ? `"${searchValue.text}"` : ''}`
								: t('allTags')}
						</Text>

						{!hasSearchValue && (
							<Box w='fit-content' ml='auto'>
								<SearchInput
									placeholder={t('searchTags') as string}
									value={searchInputValue}
									onChange={(e) => setSearchInputValue(e.target.value)}
								/>
							</Box>
						)}
					</Box>

					{hasSearchValue && (
						<Box display='flex' gap={2} width='fit-content' marginLeft='auto'>
							<Tooltip label={!searchValue.text ? t('noTextForChatGpt') : t('showChatGptResults')}>
								<Button
									isDisabled={
										isEntriesLoading || !searchValue.text || Boolean(aiConversationMessages.length)
									}
									size='sm'
									onClick={handleShowChatGptResultsClick}>
									<img alt='chat-gpt' src={chatGptLogo} style={{ width: 20, height: 20 }} />
								</Button>
							</Tooltip>
							<Button
								leftIcon={isSearchSaved ? <AiOutlineCheckCircle /> : <AiOutlineSave />}
								size='sm'
								isDisabled={isSearchSaved}
								onClick={() => setIsPageModalOpen(true)}>
								{isSearchSaved ? t('saved') : t('saveSearch')}
							</Button>
							<Button
								leftIcon={<AiOutlineClose />}
								size='sm'
								onClick={() => dispatch(fetchEntries())}>
								{t('clearSearch')}
							</Button>
						</Box>
					)}
				</Box>

				{!hasSearchValue && filteredTags.length === 0 && (
					<Text mt={2} color='GrayText' fontSize='sm'>
						{t('noTagsFound')}
					</Text>
				)}

				{(hasSearchValue ? Boolean(searchValue.tags.length) : Boolean(tags.length)) && (
					<Box display='flex' gap={2} mt={2}>
						{(hasSearchValue ? searchValue.tags : filteredTags).map((tag, index) => (
							<Tag onClick={() => handleTagClick(tag)} key={index} sx={{ cursor: 'pointer' }}>
								<TagLabel>{tag}</TagLabel>
							</Tag>
						))}
					</Box>
				)}
			</CardBody>
		</Card>
	);
};

export default ResultSummary;
