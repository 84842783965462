import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TagsInput from '../Inputs/TagsInput';

interface Props {
	isOpen: boolean;
	tags?: string[];
	onChange: (newTags: string[], deletedTags: string[]) => void;
	onClose: () => void;
}

const TagsModal: React.FC<Props> = (props) => {
	const [updatedTags, setUpdatedTags] = useState<string[]>([]);
	const { t } = useTranslation();
	const { isOpen, tags, onChange, onClose } = props;

	const handleSave = () => {
		let newTags: string[] = [];
		let deletedTags: string[] = [];

		updatedTags.forEach((tag) => {
			if (!tags?.includes(tag)) {
				newTags.push(tag);
			}
		});

		tags?.forEach((tag) => {
			if (!updatedTags.includes(tag)) {
				deletedTags.push(tag);
			}
		});

		onChange(newTags, deletedTags);

		onClose();
	};

	useEffect(() => {
		setUpdatedTags([...(tags || [])]);
	}, [tags]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent pb={4}>
				<ModalHeader>{t('tags')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<TagsInput value={updatedTags} onChange={setUpdatedTags} />
				</ModalBody>
				<ModalFooter>
					<Button colorScheme='blue' mr={3} onClick={handleSave}>
						{t('save')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default TagsModal;
