import { ChangeEvent, FocusEvent, useEffect, useMemo, useRef, useState } from 'react';
import SuggestionItem, { SUGGESTION_ITEM_CLASS, Suggestion } from './SuggestionItem';
import { Box, Input, SystemStyleObject } from '@chakra-ui/react';
import { clone, uniqueId } from 'lodash';
import { replaceRange, setCaretPosition } from '../../../helpers';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';

interface Props {
	/** Used to show suggestions only when the cursor position is at an index
	 * that matches one of the triggerRegexes. For example if "stringConcatRegExp" is used (which matches all text between curly brackets),
	 * It will show suggestions only when the cursor is between the curly brackets.
	 */
	triggerRegex?: RegExp;
	suggestions: Suggestion[];
	/** Select focused suggestion with the space key. This is used on inputs wheter the enter key does something else */
	selectSuggestionWithSpaceKey?: boolean;
	/** Will filter the suggestions list with the String.includes() method.
	 * By default it uses String.startsWith() instead
	 */
	filterWithIncludes?: boolean;
	value?: string;
	placeholder?: string;
	sx?: SystemStyleObject;
	isDisabled?: boolean;
	onSuggestionSelect?: (suggestion: Suggestion) => void;
	inputRef?: (ref: HTMLInputElement | null) => void;
	onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onEnter?: () => void;
	onBlur?: () => void;
}

const SuggestionsInput: React.FC<Props> = (props) => {
	const [currentSearchValue, setCurrentSearchValue] = useState('');
	const [isPopperOpen, setIsPopperOpen] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(0);
	const {
		triggerRegex,
		suggestions,
		selectSuggestionWithSpaceKey,
		filterWithIncludes,
		placeholder,
		sx,
		isDisabled,
		onEnter,
		inputRef,
		onSuggestionSelect,
	} = props;
	const [inputValue, setInputValue] = useState((props.value as string) || '');
	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
	const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
	const [referenceElement, setReferenceElement] = useState<HTMLInputElement | null>(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
		placement: 'bottom-start',
	});

	/** Represents the text that will be replaced when one of the visible suggestions are clicked */
	const currentSuggestionTargetRange = useRef<{ startIndex: number; endIndex: number }>({
		startIndex: 0,
		endIndex: 0,
	});

	const inputId = useMemo(() => uniqueId(), []);

	const filteredSuggestions = useMemo(
		() =>
			suggestions.filter((suggestion) => {
				if (filterWithIncludes) {
					const lowerCaseSearchValue = currentSearchValue.toLowerCase();
					return String(suggestion.searchableValue || suggestion.value)
						.toLowerCase()
						.includes(lowerCaseSearchValue);
				} else {
					return (
						String(suggestion.searchableValue || suggestion.value).startsWith(currentSearchValue) &&
						suggestion.value !== currentSearchValue
					);
				}
			}),
		[suggestions, currentSearchValue]
	);

	const handleSuggestionSelect = (suggestion: Suggestion) => {
		if (!referenceElement) {
			return;
		}

		const updatedValue = replaceRange(
			inputValue,
			currentSuggestionTargetRange.current.startIndex,
			currentSuggestionTargetRange.current.endIndex,
			String(suggestion.searchableValue || suggestion.value)
		);

		setInputValue(updatedValue);

		props.onChange?.({
			target: { ...referenceElement, value: updatedValue },
		} as ChangeEvent<HTMLInputElement>);

		setIsPopperOpen(false);

		/** Re-focuses on the input element and sets the caret position after the last character of the
		 * inserted suggestion. setTimeout is added here to focus after the popper state is updated
		 */
		setTimeout(() => {
			if (referenceElement) {
				referenceElement.focus();
				setCaretPosition(
					referenceElement,
					currentSuggestionTargetRange.current.endIndex +
						String(suggestion.searchableValue || suggestion.value).length -
						currentSearchValue.length
				);
			}
		}, 50);

		onSuggestionSelect?.(suggestion);
	};

	useEffect(() => {
		const suggestionsPopperHandler = (e: MouseEvent | KeyboardEvent) => {
			const targetElement = e.target as HTMLInputElement;
			if (targetElement.id !== inputId) {
				const className = targetElement.className;
				const isSuggestionItemClicked =
					typeof className === 'string' && className.includes(SUGGESTION_ITEM_CLASS);

				if (!isSuggestionItemClicked) {
					setIsPopperOpen(false);
				}

				return;
			}

			const inputValue = targetElement.value || '';
			const caretPosition = targetElement.selectionStart || 0;
			let isCaretInRegexMatch = false;

			if (triggerRegex) {
				let match: RegExpExecArray | null = null;
				const clonedRegex = clone(triggerRegex);

				/** Find if the current caret position is between one of the triggerRegex matches */
				while ((match = clonedRegex.exec(inputValue)) !== null) {
					/**
					 * Assuming the regex is /\[(.*?)\]/g, a match will be all text inside the brackets [...],
					 * This text could be words separated by commas. For each comma separation, we want to suggest words to the user.
					 */

					let commaIndicies: number[] = [];

					for (let i = 0; i < inputValue.length; i++) {
						if (inputValue[i] === ',') {
							commaIndicies.push(i);
						}
					}

					/** All commas that are before the caretPosition. This is used to determine the closest starting comma for the current caretPosition's word */
					const passedCommaIndicies = commaIndicies
						.map((index) => caretPosition - index)
						.filter((index) => index > 0);

					const closestStartCommaToIndex = passedCommaIndicies.indexOf(
						Math.min(...passedCommaIndicies)
					);

					const startIndex = match.index + (commaIndicies[closestStartCommaToIndex] || 0) + 1;

					const nextCommaIndicies = commaIndicies.map((index) => index - caretPosition);

					let endCommaIndex =
						commaIndicies[
							nextCommaIndicies.indexOf(Math.min(...nextCommaIndicies.filter((num) => num >= 0)))
						];

					const endIndex =
						match.index +
						(typeof endCommaIndex == 'number' ? endCommaIndex - 1 : match[1].length + 1);

					const isCurrentCaretWithinMatch =
						caretPosition >= startIndex &&
						(caretPosition <= endIndex || (endCommaIndex && caretPosition <= endCommaIndex));

					if (isCurrentCaretWithinMatch) {
						isCaretInRegexMatch = true;
						setCurrentSearchValue(match[1].slice(startIndex - 1, endIndex));
						currentSuggestionTargetRange.current = {
							startIndex,
							endIndex: endCommaIndex || endIndex,
						};
					}
				}

				if (isCaretInRegexMatch) {
					setIsPopperOpen(true);
				} else {
					setIsPopperOpen(false);
					setCurrentSearchValue('');
				}
			} else {
				setIsPopperOpen(true);
				setCurrentSearchValue(inputValue);

				currentSuggestionTargetRange.current = {
					startIndex: 0,
					endIndex: inputValue.length,
				};
			}
		};

		const mouseUpHandler = (e: MouseEvent) => {
			suggestionsPopperHandler(e);
		};

		document.addEventListener('mouseup', mouseUpHandler);
		if (isFocused) {
			const keyDownHandler = (e: KeyboardEvent) => {
				const overrideSpaceKey = Boolean(
					selectSuggestionWithSpaceKey && filteredSuggestions.length
				);

				if (!(isPopperOpen && filteredSuggestions.length) && e.key === 'Enter' && onEnter) {
					onEnter();
					return;
				}

				/** Navigate through the suggestions popup's options with arrows, and allow selection
				 * with Enter or Space
				 */
				if (
					isPopperOpen &&
					(e.key === 'ArrowUp' ||
						e.key === 'ArrowDown' ||
						e.key === 'Enter' ||
						(e.key === ' ' && overrideSpaceKey))
				) {
					e.preventDefault();
					e.stopPropagation();
					let updatedIndex = focusedSuggestionIndex;
					if (e.key === 'ArrowUp' && focusedSuggestionIndex !== 0) {
						updatedIndex -= 1;
					} else if (
						e.key === 'ArrowDown' &&
						focusedSuggestionIndex !== filteredSuggestions.length - 1
					) {
						updatedIndex += 1;
					} else if (e.key === 'Enter' || e.key === ' ') {
						const suggestion = filteredSuggestions[focusedSuggestionIndex];
						if (suggestion) {
							handleSuggestionSelect(suggestion);
						}
					}
					setFocusedSuggestionIndex(updatedIndex);
				}

				const targetElement = e.target as HTMLInputElement;
				if (!targetElement) {
					return;
				}

				const caretPosition = targetElement.selectionStart || 0;
				/** Automatically insert a closing bracket when an open one is inputted */
				if (e.key === '[' && targetElement.value[caretPosition] !== ']') {
					const updatedInputValue =
						targetElement.value.slice(0, caretPosition) +
						']' +
						targetElement.value.slice(caretPosition);

					targetElement.value = updatedInputValue;

					setCaretPosition(targetElement, caretPosition);
				}
			};

			const keyUpHandler = (e: KeyboardEvent) => {
				suggestionsPopperHandler(e);
			};

			document.addEventListener('keyup', keyUpHandler);
			document.addEventListener('keydown', keyDownHandler);

			return () => {
				document.removeEventListener('keydown', keyDownHandler);
				document.removeEventListener('keyup', keyUpHandler);
				document.removeEventListener('mouseup', mouseUpHandler);
			};
		}

		return () => {
			document.removeEventListener('mouseup', mouseUpHandler);
		};
	}, [isFocused, focusedSuggestionIndex, isPopperOpen, filteredSuggestions]);

	useEffect(() => {
		setInputValue((props.value as string) || '');
	}, [props.value]);

	useEffect(() => {
		if (isPopperOpen) {
			setFocusedSuggestionIndex(0);
		}
	}, [isPopperOpen]);

	return (
		<>
			{isPopperOpen &&
				createPortal(
					<div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
						<Box
							sx={{
								border: `1px solid #fafafa`,
								boxShadow: 'md',
								width: referenceElement?.clientWidth,
							}}>
							{filteredSuggestions.map((suggestion, index) => (
								<SuggestionItem
									suggestion={suggestion}
									key={index}
									index={index}
									onClick={handleSuggestionSelect}
									focusedSuggestionIndex={focusedSuggestionIndex}
								/>
							))}
						</Box>
						<div ref={setArrowElement} style={styles.arrow} />
					</div>,
					document.getElementById('portal')!
				)}

			<Input
				autoFocus
				isDisabled={isDisabled}
				placeholder={placeholder}
				autoComplete='off'
				id={inputId}
				ref={(ref) => {
					inputRef?.(ref);
					setReferenceElement(ref);
				}}
				value={inputValue}
				onFocus={(e) => {
					setIsFocused(true);
					props.onFocus?.(e);
				}}
				onBlur={() => {
					setIsFocused(false);
					setIsPopperOpen(false);
					props.onBlur?.();
				}}
				onChange={(e) => {
					setInputValue(e.target.value);
					setFocusedSuggestionIndex(0);
					props.onChange?.(e);
				}}
				sx={sx}
			/>
		</>
	);
};

export default SuggestionsInput;
