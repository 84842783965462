import { ReactNode } from 'react';
import { Popover, PopoverContent, PopoverBody, PopoverAnchor, Box, Text } from '@chakra-ui/react';
import { EntryUser } from '../../redux/slices/entries';

interface Props {
	anchor: ReactNode;
	isOpen: boolean;
	user: EntryUser;
	onOpen: () => void;
	onClose: () => void;
}

const UserPopover: React.FC<Props> = ({ anchor, isOpen, user, onOpen, onClose }) => {
	return (
		<Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
			<PopoverAnchor>
				<Box>{anchor}</Box>
			</PopoverAnchor>

			<PopoverContent>
				<PopoverBody>
					<Text fontWeight={600}>{user?.name}</Text>
					<Text>{user?.email}</Text>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default UserPopover;
