import { Box, Text } from '@chakra-ui/react';
import SearchEntry from './SearchEntry';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StoreState, useAppDispatch } from '../../../redux/store';
import {
	SearchValue,
	setSearchValue,
	updateRecentSearches,
} from '../../../redux/slices/entries-search';
import { fetchEntries } from '../../../redux/slices/entries';

const RecentSearches = () => {
	const recentSearches = useSelector((state: StoreState) => state.entriesSearch.recentSearches);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const handleSearchClick = (search: SearchValue) => {
		dispatch(setSearchValue(search));
		dispatch(fetchEntries(search));
	};

	const handleDeleteClick = (index: number) => {
		dispatch(
			updateRecentSearches([...recentSearches.filter((item, itemIndex) => itemIndex !== index)])
		);
	};

	return (
		<Box height='50%'>
			{!recentSearches.length && (
				<Text mt={3} fontSize='xs' fontWeight={400}>
					{t('noRecentSearches')}
				</Text>
			)}

			<Box padding={1} overflowY='auto' height='calc(100% - 150px)'>
				{recentSearches.map((search, index) => (
					<SearchEntry
						searchEntry={search}
						key={index}
						onClick={handleSearchClick}
						onDeleteClick={() => handleDeleteClick(index)}
					/>
				))}
			</Box>
		</Box>
	);
};

export default RecentSearches;
