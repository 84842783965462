import {
	Menu,
	Icon,
	IconButton,
	MenuButton,
	MenuList,
	MenuDivider,
	MenuGroup,
	MenuItem,
	Portal,
} from '@chakra-ui/react';
import {
	AiOutlineFileAdd,
	AiOutlineDelete,
	AiOutlineFolderAdd,
	AiOutlineMore,
} from 'react-icons/ai';
import { TFolder } from './Pages';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog';
import { useAppDispatch } from '../../../../redux/store';
import { PLACEHOLDER_PAGE_ID, deletePages } from '../../../../redux/slices/pages';

interface Props {
	isVisible: boolean;
	folder: TFolder;
	onDelete: (folder: TFolder) => void;
	onAddFolderClick: (folder: TFolder) => void;
	onAddPageClick: (folder: TFolder) => void;
}

const FolderMenu: React.FC<Props> = ({
	folder,
	isVisible,
	onAddPageClick,
	onAddFolderClick,
	onDelete,
}) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const dispatch = useAppDispatch();

	const handleDelete = async () => {
		let deletedPageIds: string[] = [];

		const getFolderPages = (folder: TFolder) => {
			deletedPageIds = [
				...deletedPageIds,
				...folder.pages.map((page) => page._id).filter((id) => id !== PLACEHOLDER_PAGE_ID),
			];

			folder.nestedFolders.forEach((nestedFolder) => {
				getFolderPages(nestedFolder);
			});
		};

		getFolderPages(folder);

		if (deletedPageIds.length) {
			setIsDeleteLoading(true);
			await dispatch(deletePages(deletedPageIds));
			setIsDeleteLoading(false);
		}

		onDelete(folder);
		setIsDeleteConfirmDialogOpen(false);
	};

	return (
		<>
			<ConfirmDialog
				isOpen={isDeleteConfirmDialogOpen}
				title={t('deleteFolderConfirmTitle')}
				actionButtonContent={t('delete')}
				onAction={handleDelete}
				isActionLoading={isDeleteLoading}
				onClose={() => setIsDeleteConfirmDialogOpen(false)}
			/>
			<Menu onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
				<MenuButton
					visibility={isVisible || isOpen ? 'visible' : 'hidden'}
					size='sm'
					as={IconButton}
					backgroundColor='transparent'>
					<Icon as={AiOutlineMore} fontSize={18} />
				</MenuButton>
				<Portal>
					<MenuList>
						<MenuGroup>
							<MenuItem onClick={() => onAddPageClick(folder)}>
								<Icon as={AiOutlineFileAdd} mr={4} ml={2} />
								{t('newPage')}
							</MenuItem>
							<MenuItem onClick={() => onAddFolderClick(folder)}>
								<Icon as={AiOutlineFolderAdd} mr={4} ml={2} />
								{t('newFolder')}
							</MenuItem>
						</MenuGroup>
						<MenuDivider />

						<MenuGroup>
							{/* <MenuItem>
						<Icon as={FiEdit2} mr={4} ml={2} />
						{t('edit')}
					</MenuItem> */}
							<MenuItem onClick={() => setIsDeleteConfirmDialogOpen(true)}>
								<Icon as={AiOutlineDelete} mr={4} ml={2} />
								{t('delete')}
							</MenuItem>
						</MenuGroup>
					</MenuList>
				</Portal>
			</Menu>
		</>
	);
};

export default FolderMenu;
