import { Box, Card, CardBody, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GrFormAdd } from 'react-icons/gr';

const AddButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
	const { t } = useTranslation();

	return (
		<Card onClick={onClick} size='sm' mb={4} mt={4} cursor='pointer'>
			<CardBody>
				<Box gap={2} w='fit-content' mx='auto' display='flex' alignItems='center'>
					<Icon as={GrFormAdd} />
					<Text fontSize={14}>{t('addEntry')}</Text>
				</Box>
			</CardBody>
		</Card>
	);
};

export default AddButton;
