import { Box, CircularProgress, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StoreState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {
	PLACEHOLDER_PAGE_ID,
	Page,
	deletePages,
	enrichPageData,
	getPages,
} from '../../../../redux/slices/pages';
import PageModal from '../../../Modals/PageModal';
import Folder from './Folder';
// import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
// import { getListStyle } from './utils';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FolderModal from '../../../Modals/FolderModal';
import AddButtons from './AddButtons';
import SearchInput from '../../../Inputs/SearchInput';
import { fetchEntries } from '../../../../redux/slices/entries';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog';
import { setSearchValue } from '../../../../redux/slices/entries-search';

export type TFolder = {
	name: string;
	path: string;
	pages: Page[];
	nestedFolders: TFolder[];
};

/** Page is equivalent to SavedSearch */
const Pages = () => {
	const [folders, setFolders] = useState<TFolder[]>([]);
	const [expandedFolderPaths, setExpandedFolderPaths] = useState<string[]>([]);
	const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
	const [isPageModalOpen, setIsPageModalOpen] = useState(false);
	const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [selectedFolder, setSelectedFolder] = useState<TFolder | null>(null);
	const [selectedParentFolderPath, setSelectedParentFolderPath] = useState('');
	const [selectedPage, setSelectedPage] = useState<Page | null>(null);
	/** The sole purpose of these pages to maintain the existence of empty folders */
	const [placeholderPages, setPlaceholderPages] = useState<Page[]>([]);
	const [pagesSearchValue, setPagesSearchValue] = useState('');
	const savedPages = useSelector((state: StoreState) => state.pages.pages);
	const isPagesLoading = useSelector((state: StoreState) => state.pages.isPagesLoading);
	const selectedWorkspaceId = useSelector(
		(state: StoreState) => state.workspaces.selectedWorkspaceId
	);

	const allPages = useMemo(() => {
		let allPages = [...placeholderPages, ...savedPages];
		const lowerCaseSearchValue = pagesSearchValue.toLowerCase();

		return allPages.filter(
			(page) =>
				page.title.toLowerCase().includes(lowerCaseSearchValue) ||
				page.searchText.toLowerCase().includes(lowerCaseSearchValue) ||
				page.folderPath?.toLowerCase().includes(lowerCaseSearchValue)
		);
	}, [placeholderPages, savedPages, pagesSearchValue]);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const rootLevelPages = useMemo(() => allPages.filter((page) => !page.folderPath), [allPages]);

	const handlePageClick = (page: Page) => {
		dispatch(
			setSearchValue({ _id: page._id, text: page.searchText, tags: page.tags, isSavedSearch: true })
		);
		dispatch(fetchEntries({ text: page.searchText, tags: page.tags }));
	};

	const handleFolderDelete = (folder: TFolder) => {
		setPlaceholderPages(
			placeholderPages.filter((page) => page.folderPath?.startsWith(folder.path))
		);
	};

	const handleDeleteClick = async (page: Page) => {
		setSelectedPage(page);
		setIsDeleteConfirmDialogOpen(true);
	};

	const handleDelete = async () => {
		if (selectedPage) {
			setIsDeleteLoading(true);
			await dispatch(deletePages([selectedPage._id]));
			setSelectedPage(null);
			setIsDeleteConfirmDialogOpen(false);
			setIsDeleteLoading(false);
		}
	};

	const handleEditClick = (page: Page) => {
		setSelectedPage(page);
		setIsPageModalOpen(true);
	};

	useEffect(() => {
		dispatch(getPages());
	}, [selectedWorkspaceId]);

	// TODO: When one folder is removed due to a page folder path change, add it to placeholderPages to prevent it from completely disappearing
	useEffect(() => {
		let folders: TFolder[] = [];
		let expandedFolderPaths: string[] = [];

		allPages.forEach((view) => {
			if (!view.folderPath) {
				return;
			}

			const findFolderAndAddPage = (folders: TFolder[], parentPath: string) => {
				let groupFound = false;

				for (const folder of folders) {
					if (folder.path === view.folderPath) {
						folder.pages.push(view);
						groupFound = true;
						break;
					} else if (view.folderPath?.startsWith(folder.path)) {
						findFolderAndAddPage(folder.nestedFolders, folder.path);
						groupFound = true;
						break;
					}
				}

				if (!groupFound) {
					let currentFolders: TFolder[] = folders;

					let currentPath = parentPath
						? view.folderPath!.split(parentPath)[1].split('/')[1]
						: view.folderPath!.split('/')[0];

					while (currentPath) {
						const newGroupPath = parentPath ? parentPath + '/' + currentPath : currentPath;
						const splitNewGroupPath = newGroupPath.split('/');
						const nextPath = view.folderPath!.split(newGroupPath)[1].split('/')[1];

						const newFolder = {
							name: splitNewGroupPath[splitNewGroupPath.length - 1],
							path: newGroupPath,
							pages: nextPath ? [] : [view],
							nestedFolders: [],
						};

						if (!placeholderPages.find((page) => page.folderPath === newFolder.path)) {
							placeholderPages.push(
								enrichPageData({ _id: PLACEHOLDER_PAGE_ID, folderPath: newFolder.path })
							);
						}

						currentFolders.push(newFolder);

						expandedFolderPaths.push(newGroupPath);

						parentPath = newGroupPath;

						currentFolders = currentFolders[currentFolders.length - 1].nestedFolders;
						currentPath = nextPath;
					}
				}
			};

			findFolderAndAddPage(folders, '');
		});

		setExpandedFolderPaths(expandedFolderPaths);
		setFolders(folders);
	}, [allPages, placeholderPages]);

	return (
		<Box>
			<ConfirmDialog
				isOpen={isDeleteConfirmDialogOpen}
				title={t('deletePage')}
				isActionLoading={isDeleteLoading}
				actionButtonContent={t('delete')}
				onClose={() => setIsDeleteConfirmDialogOpen(false)}
				onAction={handleDelete}
			/>
			<PageModal
				isOpen={isPageModalOpen}
				selectedPage={selectedPage}
				defaultInputValues={{ folderPath: selectedParentFolderPath || '' }}
				onClose={() => setIsPageModalOpen(false)}
			/>

			<FolderModal
				isOpen={isFolderModalOpen}
				selectedFolder={selectedFolder}
				parentFolderPath={selectedParentFolderPath}
				allPages={allPages}
				onClose={() => setIsFolderModalOpen(false)}
				onSave={(newFolderName) => {
					let newPagePath = `${newFolderName}`;

					if (selectedParentFolderPath) {
						newPagePath = `${selectedParentFolderPath}/${newPagePath}`;
					}

					setPlaceholderPages([
						...placeholderPages,
						enrichPageData({ _id: PLACEHOLDER_PAGE_ID, folderPath: newPagePath }),
					]);
				}}
			/>

			<Box mb={4} display='flex' alignItems='center'>
				<Box mr={2}>
					<SearchInput
						onChange={(e) => setPagesSearchValue(e.target.value)}
						value={pagesSearchValue}
					/>
				</Box>

				<AddButtons
					onAddPageClick={() => {
						setIsPageModalOpen(true);
						setSelectedPage(null);
					}}
					onAddFolderClick={() => {
						setSelectedFolder(null);
						setSelectedParentFolderPath('');
						setIsFolderModalOpen(true);
					}}
				/>
			</Box>

			{isPagesLoading && (
				<Box width='fit-content' mx='auto'>
					<CircularProgress isIndeterminate size={6} />
				</Box>
			)}

			{allPages.length === 0 && !isPagesLoading && (
				<Text fontSize='xs' fontWeight={400}>
					{t('noPages')}
				</Text>
			)}

			{!isPagesLoading && Boolean(allPages.length) && (
				<DndProvider backend={HTML5Backend}>
					<Folder
						style={{ height: 'calc(100% - 50px)' }}
						onAddFolderClick={(folder) => {
							setSelectedFolder(null);
							setSelectedParentFolderPath(folder.path);
							setIsFolderModalOpen(true);
						}}
						onAddPageClick={(folder) => {
							setSelectedParentFolderPath(folder.path);
							setSelectedPage(null);
							setIsPageModalOpen(true);
						}}
						onPageClick={handlePageClick}
						onEditClick={handleEditClick}
						onDeleteClick={handleDeleteClick}
						onFolderDelete={handleFolderDelete}
						expandedFolderPaths={expandedFolderPaths}
						setExpandedFolderPaths={setExpandedFolderPaths}
						folder={{ name: '', path: '', pages: rootLevelPages, nestedFolders: folders }}
					/>
				</DndProvider>
			)}
		</Box>
	);
};

export default Pages;
