import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
} from '@chakra-ui/react';
import { User } from '../../../redux/slices/current-user';
import { useTranslation } from 'react-i18next';
import Settings from './Settings';
import Workspaces from './Workspaces';

interface Props {
	isOpen: boolean;
	user: User;
	onClose: () => void;
}

const UserModal: React.FC<Props> = ({ isOpen, user, onClose }) => {
	const { t } = useTranslation();

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent pb={4}>
				<ModalHeader>{t('editUser')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Settings user={user} />
					<Workspaces user={user} />
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default UserModal;
