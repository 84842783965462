import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import {
	Route,
	createRoutesFromElements,
	createBrowserRouter,
	RouterProvider,
} from 'react-router-dom';
import HomePage from './Pages/Home';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import LoginPage from './Pages/Login';
import './index.css';
import AdminUsersPage from './Pages/Admin/Users';
import AdminWorkspacesPage from './Pages/Admin/Workspaces';
import AdminSettingsPage from './Pages/Admin/Settings';

export const ADMIN_PAGE_BASE_PATH = '/admin';
export const ADMIN_USERS_PAGE_PATH = ADMIN_PAGE_BASE_PATH + '/users';
export const ADMIN_WORKSPACES_PAGE_PATH = ADMIN_PAGE_BASE_PATH + '/workspaces';
export const ADMIN_SETTINGS_PAGE_PATH = ADMIN_PAGE_BASE_PATH + '/settings';
export const LOGIN_PAGE_PATH = '/login';

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path='/' element={<HomePage />} />
			<Route path={LOGIN_PAGE_PATH} element={<LoginPage />} />
			<Route path={ADMIN_USERS_PAGE_PATH} element={<AdminUsersPage />} />
			<Route path={ADMIN_WORKSPACES_PAGE_PATH} element={<AdminWorkspacesPage />} />
			<Route path={ADMIN_SETTINGS_PAGE_PATH} element={<AdminSettingsPage />} />
		</>
	)
);

const theme = extendTheme({
	fonts: {
		heading: `'Noto Sans', sans-serif`,
		body: `'Noto Sans', sans-serif`,
	},
});

const App = () => {
	return (
		<Provider store={store}>
			<ChakraProvider theme={theme}>
				<div style={{ zIndex: 99999, position: 'absolute' }} id='portal'></div>
				<I18nextProvider i18n={i18n} defaultNS='common'>
					<RouterProvider router={router} />
				</I18nextProvider>
			</ChakraProvider>
		</Provider>
	);
};

export default App;
