import { useTranslation } from 'react-i18next';
import { FilterWord } from '../../../redux/slices/general-settings';
import {
	Box,
	Divider,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Tag,
	TagLabel,
	Text,
	useToast,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import SearchInput from '../../Inputs/SearchInput';
import { trim } from 'lodash';
import { AiOutlineCopy } from 'react-icons/ai';

interface Props {
	isOpen: boolean;
	words: FilterWord[];
	name: 'allowWords' | 'denyWords';
	onClose: () => void;
}

const WordsModal: React.FC<Props> = ({ isOpen, name, words, onClose }) => {
	const [searchValue, setSearchValue] = useState('');
	const { t } = useTranslation();

	const filteredWords = useMemo(() => {
		const lowerCaseSearchValue = trim(searchValue.toLowerCase());
		let filteredWords: FilterWord[] = [];

		if (searchValue) {
			words.forEach((word) => {
				const filteredSynonyms = word.synonyms.filter((word) =>
					word.includes(lowerCaseSearchValue)
				);

				if (filteredSynonyms.length || word.word.includes(lowerCaseSearchValue)) {
					filteredWords.push({
						...word,
						synonyms: filteredSynonyms,
					});
				}
			});
		} else {
			return words;
		}

		return filteredWords;
	}, [words, searchValue]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent pb={4}>
				<ModalHeader>{t(name)}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<SearchInput
						value={searchValue}
						placeholder={t('searchWords') as string}
						onChange={(e) => setSearchValue(e.target.value)}
						onClearClick={() => setSearchValue('')}
					/>
					<Box mt={4}>
						{filteredWords.map((word, index) => (
							<WordItem word={word} key={index} />
						))}

						{!filteredWords.length && <Text fontSize={14}>{t('noWordsFound')}</Text>}
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

const WordItem: React.FC<{ word: FilterWord }> = ({ word }) => {
	const [isHovered, setIsHovered] = useState(false);
	const { t } = useTranslation();
	const toast = useToast();

	return (
		<>
			<Box py={3} display='flex' gap={4}>
				<Box
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					onClick={() => {
						navigator.clipboard.writeText(word.word);
						toast({
							title: t('copiedToClipboard'),
							status: 'success',
						});
					}}
					cursor='pointer'
					display='flex'
					gap={2}
					width={100}
					alignItems='center'>
					<Text fontSize={12} fontWeight={600}>
						{word.word}
					</Text>

					{isHovered && <Icon fontSize='sm' as={AiOutlineCopy} />}
				</Box>
				<Box width='calc(100% - 100px)' display='flex' flexWrap='wrap' gap={2}>
					{word.synonyms.map((synonym, index) => (
						<WordSynonym word={synonym} key={index} />
					))}
				</Box>
			</Box>
			<Divider />
		</>
	);
};

const WordSynonym: React.FC<{ word: string }> = ({ word }) => {
	const [isHovered, setIsHovered] = useState(false);
	const { t } = useTranslation();
	const toast = useToast();

	return (
		<Tag
			cursor='pointer'
			size='sm'
			display='flex'
			alignItems='center'
			position='relative'
			onClick={() => {
				navigator.clipboard.writeText(word);
				toast({
					title: t('copiedToClipboard'),
					status: 'success',
				});
			}}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}>
			<TagLabel opacity={isHovered ? 0.1 : 1}>{word}</TagLabel>
			{isHovered && (
				<Box
					width='100%'
					display='flex'
					position='absolute'
					left={0}
					alignItems='center'
					justifyContent='center'
					overflow='hidden'>
					<Icon fontSize='sm' as={AiOutlineCopy} />
				</Box>
			)}
		</Tag>
	);
};

export default WordsModal;
