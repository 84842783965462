import NavItem from '../NavItem';
import { BsPeople, BsPerson } from 'react-icons/bs';
import {
	ADMIN_USERS_PAGE_PATH,
	ADMIN_WORKSPACES_PAGE_PATH,
	ADMIN_SETTINGS_PAGE_PATH,
} from '../../../App';
import { APP_NAME } from '../../../config';
import { AiOutlineHome } from 'react-icons/ai';
import { setSelectedWorkspace } from '../../../redux/slices/workspaces';
import { FiSettings } from 'react-icons/fi';
import { Box, CircularProgress, CloseButton, Divider, Flex, Icon, Text } from '@chakra-ui/react';
import { StoreState, useAppDispatch } from '../../../redux/store';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { GrUserAdmin } from 'react-icons/gr';
import UserMenu from '../UserMenu';

interface Props {
	onClose: () => void;
}

const MainSidebar: React.FC<Props> = ({ onClose }) => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const userInfo = useSelector((state: StoreState) => state.currentUser.userInfo);
	const { workspaces, selectedWorkspaceId, isWorkspacesLoading } = useSelector(
		(state: StoreState) => state.workspaces
	);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const isInHomePage = useMemo(() => location.pathname === '/', [location]);

	const handleWorkspaceClick = (workspaceId: string | null) => {
		if (!isInHomePage) {
			navigate('/');
		}
		dispatch(setSelectedWorkspace(workspaceId));
	};

	return (
		<Box display='flex' flexDirection='column' width={isInHomePage ? '40%' : '100%'}>
			<Box px={3} mt={4} mb={8}>
				<Flex alignItems='center' justifyContent='space-between'>
					<Text onClick={() => navigate('/')} cursor='pointer' fontSize='xl' fontWeight='bold'>
						{APP_NAME}
					</Text>
					<CloseButton display={{ base: 'flex', lg: 'none' }} onClick={onClose} />
				</Flex>
			</Box>

			<NavItem icon={AiOutlineHome} path='/'>
				{t('home')}
			</NavItem>

			<Divider width='100%' mt={4} />

			<Text mx={5} fontSize={12} color='gray.600' mt={6} mb={3}>
				<Icon as={BsPeople} mr={2} />

				{t('workspaces')}
			</Text>

			{isWorkspacesLoading && (
				<Box width='fit-content' mx='auto'>
					<CircularProgress isIndeterminate size={8} />
				</Box>
			)}

			{!isWorkspacesLoading && (
				<Box overflowY='auto'>
					<NavItem
						lightSelection
						onClick={() => {
							handleWorkspaceClick(null);
						}}
						isSelected={!selectedWorkspaceId && isInHomePage}>
						All Organization
					</NavItem>
					{workspaces.map((workspace, index) => (
						<NavItem
							lightSelection
							key={index}
							isSelected={isInHomePage && selectedWorkspaceId === workspace._id}
							onClick={() => {
								handleWorkspaceClick(workspace._id);
							}}>
							{workspace.name}
						</NavItem>
					))}
				</Box>
			)}

			<Divider width='100%' mt={4} />

			{userInfo?.isAdmin && (
				<>
					<Text mx={5} fontSize={12} color='gray.600' mt={4} mb={3}>
						<Icon as={GrUserAdmin} mr={2} />
						{t('adminPanel')}
					</Text>

					<NavItem icon={BsPerson} path={ADMIN_USERS_PAGE_PATH}>
						{t('users')}
					</NavItem>
					<NavItem icon={BsPeople} path={ADMIN_WORKSPACES_PAGE_PATH}>
						{t('workspaces')}
					</NavItem>
					<NavItem icon={FiSettings} path={ADMIN_SETTINGS_PAGE_PATH}>
						{t('generalSettings')}
					</NavItem>
				</>
			)}

			<Box py={3} mt='auto'>
				<Divider mb={2} />
				<Box w='fit-content' mx='auto'>
					<UserMenu />
				</Box>
			</Box>
		</Box>
	);
};

export default MainSidebar;
