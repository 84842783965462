import { useEffect, useState } from 'react';
import { User } from '../../../redux/slices/current-user';
import { Workspace } from '../../../redux/slices/workspaces';
import { API_URL } from '../../../config';
import axios from 'axios';
import {
	Box,
	Card,
	CardBody,
	Icon,
	Heading,
	CircularProgress,
	Text,
	IconButton,
	Tooltip,
	useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsPeople } from 'react-icons/bs';
import { IoMdExit } from 'react-icons/io';
import AddWorkspacePopover from './AddWorkspacePopover';

interface Props {
	user: User;
}

const Workspaces: React.FC<Props> = ({ user }) => {
	const { t } = useTranslation();
	const [hoveredWorkspace, setHoveredWorkspace] = useState<Workspace | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
	const [loadingWorkspaceLeaveButton, setLoadingWorkspaceLeaveButton] = useState<Workspace | null>(
		null
	);
	const toast = useToast();

	const getWorkspaces = async () => {
		setIsLoading(true);
		const result = await axios.get(`${API_URL}/users/${user._id}/workspaces`);

		setWorkspaces(result.data.workspaces);
		setIsLoading(false);
	};

	const removeUserFromWorkspace = async (workspace: Workspace) => {
		setLoadingWorkspaceLeaveButton(workspace);

		await axios.delete(`${API_URL}/workspaces/${workspace._id}/users/${user._id}`);

		toast({
			title: (
				<>
					Removed <b>{user.name}</b> from {workspace.name}
				</>
			),
			status: 'success',
			position: 'top-right',
			isClosable: true,
		});
		setLoadingWorkspaceLeaveButton(null);
		setWorkspaces(workspaces.filter(({ _id }) => _id !== workspace._id));
	};

	useEffect(() => {
		getWorkspaces();
	}, []);

	return (
		<Card mt={4}>
			<CardBody bgColor='gray.50'>
				<Box mb={4} display='flex' alignItems='center'>
					<Icon mr={3} fontSize='lg' as={BsPeople} />
					<Heading fontSize='lg'>{t('workspaces')}</Heading>

					<AddWorkspacePopover
						user={user}
						userWorkspaces={workspaces}
						onAdd={(newWorkspace) => setWorkspaces([...workspaces, newWorkspace])}
					/>
				</Box>

				{isLoading && (
					<Box mt={4} width='fit-content' mx='auto'>
						<CircularProgress size='25px' isIndeterminate />
					</Box>
				)}

				{!isLoading &&
					workspaces.map((workspace, index) => (
						<Card
							onMouseEnter={() => setHoveredWorkspace(workspace)}
							onMouseLeave={() => setHoveredWorkspace(null)}
							mb={2}
							size='sm'
							key={index}>
							<CardBody display='flex' alignItems='center'>
								<Text fontSize={14}>{workspace.name}</Text>

								<Box
									visibility={hoveredWorkspace?._id === workspace._id ? 'visible' : 'hidden'}
									ml='auto'
									width='fit-content'>
									<Tooltip label={t('removeUserFromWorkspace')}>
										<IconButton
											onClick={() => removeUserFromWorkspace(workspace)}
											isLoading={loadingWorkspaceLeaveButton?._id === workspace._id}
											size='xs'
											aria-label='delete'>
											<Icon as={IoMdExit} />
										</IconButton>
									</Tooltip>
								</Box>
							</CardBody>
						</Card>
					))}

				{!isLoading && workspaces.length === 0 && (
					<Text fontWeight={400} fontSize={14}>
						{t('userNotInWorkspace')}
					</Text>
				)}
			</CardBody>
		</Card>
	);
};

export default Workspaces;
