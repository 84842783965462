import { Flex, useColorModeValue, IconButton, Box, FlexProps } from '@chakra-ui/react';
import { FiBell, FiMenu } from 'react-icons/fi';
import { SIDE_BAR_WIDTH } from './SidebarContent/SidebarContent';
import UserMenu from './UserMenu';

interface Props extends FlexProps {
	onDrawerOpen: () => void;
}

const Header: React.FC<Props> = ({ onDrawerOpen, ...rest }) => {
	return (
		<Flex
			ml={{ base: 0, lg: SIDE_BAR_WIDTH + 'px' }}
			px={{ base: 4, md: 4 }}
			height={HEADER_HEIGHT + 'px'}
			alignItems='center'
			justifyContent='center'
			bg={useColorModeValue('white', 'gray.900')}
			borderBottomWidth='1px'
			borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
			{...rest}>
			<IconButton
				position='absolute'
				left={6}
				display={{ base: 'flex', lg: 'none' }}
				onClick={onDrawerOpen}
				variant='outline'
				aria-label='open menu'
				icon={<FiMenu />}
			/>

			<Box display='flex' alignItems='center' position='absolute' right={6}>
				<IconButton mr={4} size='lg' variant='ghost' aria-label='open menu' icon={<FiBell />} />
				<Flex alignItems={'center'}>
					<UserMenu />
				</Flex>
			</Box>
		</Flex>
	);
};

export const HEADER_HEIGHT = 80;

export default Header;
