import { Box, Button, Icon, Text, Tooltip } from '@chakra-ui/react';
import { AiOutlineTag } from 'react-icons/ai';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import { Entry, createEntry, updateEntry } from '../../../redux/slices/entries';
import { useEffect, useRef, useState } from 'react';
import TagsModal from '../../Modals/TagsModal';
import { isMacOS } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';

interface Props {
	entry?: Entry;
	defaultTags?: string[];
	onMoveToNextEntry?: () => void;
	onMoveToPrevEntry?: () => void;
	onCancel: () => void;
}

const EntryItemEditMode: React.FC<Props> = ({
	onCancel,
	onMoveToNextEntry,
	onMoveToPrevEntry,
	defaultTags,
	entry,
}) => {
	const { t } = useTranslation();
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
	const [inputValues, setInputValues] = useState<
		Partial<Entry & { newTags: string[]; deletedTags: string[] }>
	>({});

	const hasUnsavedChanges = useRef(false);

	const dispatch = useAppDispatch();

	const handleCancelClick = () => {
		onCancel?.();
	};

	const handleSaveClick = async () => {
		if(!inputValues.textOnlyContent) {
			return
		};
		
		setIsSaveLoading(true);
		if (entry) {
			await dispatch(updateEntry(inputValues));
		} else {
			await dispatch(createEntry(inputValues));
		}
		setIsSaveLoading(false);

		hasUnsavedChanges.current = false;
		onCancel?.();
	};

	useEffect(() => {
		setInputValues({ ...entry, tags: [...(entry?.tags || []), ...(defaultTags || [])] });
	}, [entry]);

	useEffect(() => {
		const keyDownHandler = async (e: KeyboardEvent) => {
			const isMetaKeyPressed = isMacOS() ? e.metaKey : e.ctrlKey;

			if (isMetaKeyPressed && e.code === 'Enter') {
				e.preventDefault();
				e.stopPropagation();
				// TODO: Show an error when trying to save an entry without text
				if (inputValues.textOnlyContent && hasUnsavedChanges.current) {
					await handleSaveClick();
				} else {
					onCancel?.();
				}

				/**  // TODO: e.shiftKey should only work when there's an entry before the current one. Otherwise,
				 *  it should be depending on the selected sorting
				 */
				if (e.shiftKey) {
					onMoveToPrevEntry?.();
				} else {
					onMoveToNextEntry?.();
				}

				document.removeEventListener('keydown', keyDownHandler);
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [entry, inputValues, onCancel, onMoveToNextEntry, onMoveToPrevEntry]);

	return (
		<>
			<TagsModal
				isOpen={isTagsModalOpen}
				tags={inputValues?.tags}
				onChange={(newTags, deletedTags) => {
					newTags.forEach((tag) => {
						inputValues.tags = [tag, ...(inputValues.tags || [])];
					});

					deletedTags.forEach((tag) => {
						inputValues.tags = inputValues.tags?.filter((existingTag) => existingTag !== tag);
					});

					hasUnsavedChanges.current = true;
					setInputValues({ ...inputValues, newTags, deletedTags });
				}}
				onClose={() => setIsTagsModalOpen(false)}
			/>

			<Box display='flex' alignItems='center' py={2}>
				<Text mb={3} fontSize='lg'>
					{entry ? t('edit') : t('new')} {t('entry')}
				</Text>
				<Box width='fit-content' ml='auto'>
					<Tooltip label={inputValues.tags?.join(', ')}>
						<Button
							size='sm'
							onClick={() => setIsTagsModalOpen(true)}
							leftIcon={<Icon as={AiOutlineTag} />}
							colorScheme='teal'
							variant='outline'>
							{t('tags')} ({inputValues?.tags?.length || 0})
						</Button>
					</Tooltip>
				</Box>
			</Box>
			<RichTextEditor
				onChange={(editorStateJson, text) => {
					hasUnsavedChanges.current = true;
					setInputValues({
						...inputValues,
						richContent: editorStateJson,
						textOnlyContent: text,
					});
				}}
				defaultValue={entry?.richContent}
			/>
			<Box width='fit-content' ml='auto' display='flex' gap={2}>
				<Button size='sm' onClick={handleCancelClick} colorScheme='teal' variant='ghost'>
					{t('cancel')}
				</Button>

				<Button
					isDisabled={!inputValues.textOnlyContent}
					isLoading={isSaveLoading}
					size='sm'
					onClick={handleSaveClick}
					colorScheme='teal'
					variant='solid'>
					{t('save')}
				</Button>
			</Box>
		</>
	);
};

export default EntryItemEditMode;
