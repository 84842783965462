import {
	Box,
	Button,
	CircularProgress,
	Heading,
	Icon,
	IconButton,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import PageContainer from '../../Components/PageContainer';
import { useTranslation } from 'react-i18next';
import { StoreState, useAppDispatch } from '../../redux/store';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUsers } from '../../redux/slices/users';
import { FiEdit2 } from 'react-icons/fi';
import UserModal from '../../Components/Modals/UserModal/UserModal';
import { User } from '../../redux/slices/current-user';
import { BsPerson } from 'react-icons/bs';
import SearchInput from '../../Components/Inputs/SearchInput';

const AdminUsersPage = () => {
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState<User>();
	const [searchValue, setSearchValue] = useState('');
	const { users, isUsersLoading } = useSelector((state: StoreState) => state.users);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const filteredUsers = useMemo(() => {
		if (searchValue) {
			const lowerCaseSearchValue = searchValue.toLowerCase();

			return users.filter(
				(user) =>
					user.name.toLowerCase().includes(lowerCaseSearchValue) ||
					user.email.includes(lowerCaseSearchValue)
			);
		} else {
			return users;
		}
	}, [users, searchValue]);

	useEffect(() => {
		if (!users.length) {
			dispatch(getUsers());
		}
	}, []);

	return (
		<PageContainer>
			{selectedUser && (
				<UserModal
					isOpen={isUserModalOpen}
					user={selectedUser}
					onClose={() => setIsUserModalOpen(false)}
				/>
			)}
			<Box mt={4} display='flex' alignItems='center'>
				<Icon mr={3} fontSize='2xl' as={BsPerson} />
				<Heading fontSize='2xl'>{t('users')}</Heading>

				<Box display='flex' gap={4} alignItems='center' width='fit-content' ml='auto'>
					<Box width={200}>
						<SearchInput
							inputSx={{ backgroundColor: 'white' }}
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
					</Box>
					<Button size='sm' colorScheme='blue'>
						{t('manageAccounts')}
					</Button>
				</Box>
			</Box>

			{isUsersLoading && (
				<Box w='fit-content' mx='auto'>
					<CircularProgress isIndeterminate size='30px' />
				</Box>
			)}

			{!isUsersLoading && (
				<TableContainer mt={6} backgroundColor='white' borderRadius={4}>
					<Table variant='simple'>
						<Thead>
							<Tr>
								<Th>{t('name')}</Th>
								<Th>{t('emailAddress')}</Th>
								<Th display='flex' alignItems='center' justifyContent='center'>
									{t('actions')}
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{filteredUsers.map((user, index) => (
								<Tr key={index}>
									<Td>{user.name}</Td>
									<Td>{user.email}</Td>
									<Td display='flex' alignItems='center' justifyContent='center'>
										<IconButton
											size='sm'
											onClick={() => {
												setIsUserModalOpen(true);
												setSelectedUser(user);
											}}
											aria-label='edit-user'>
											<Icon as={FiEdit2} />
										</IconButton>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					{!filteredUsers.length && !isUsersLoading && (
						<Text p={4} textAlign='center'>
							{t('noUsersFound')}
						</Text>
					)}
				</TableContainer>
			)}
		</PageContainer>
	);
};

export default AdminUsersPage;
