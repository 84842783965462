import { Box, Button, Icon, Tooltip } from '@chakra-ui/react';
import { StoreState, useAppDispatch } from '../../redux/store';
import { setEntriesSort } from '../../redux/slices/entries';
import { useSelector } from 'react-redux';
import { BsSortAlphaDown, BsSortAlphaUpAlt } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const EntriesSort = () => {
	const dispatch = useAppDispatch();
	const sort = useSelector((state: StoreState) => state.entries.sort);
	const { t } = useTranslation();

	return (
		<Box width='fit-content' mt={2} mb={2} ml='auto'>
			<Tooltip label={t('sort')}>
				<Button
					onClick={() =>
						dispatch(setEntriesSort(sort === 'ascending' ? 'descending' : 'ascending'))
					}
					leftIcon={
						sort === 'ascending' ? (
							<Icon fontSize={20} as={BsSortAlphaDown} />
						) : (
							<Icon fontSize={20} as={BsSortAlphaUpAlt} />
						)
					}>
					{sort === 'ascending' ? 'Oldest' : 'Newest'} First
				</Button>
			</Tooltip>
		</Box>
	);
};

export default EntriesSort;
