import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CLEAR_HISTORY_COMMAND } from 'lexical';
import React, { useEffect } from 'react';

const UpdatePlugin: React.FC<{ value: string }> = ({ value }) => {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		editor.setEditorState(JSON.parse(value));
		editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
	}, [editor, value]);

	return null;
};

export default UpdatePlugin;
