import { Card, CardBody } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Entry } from '../../../redux/slices/entries';
import EntryItemEditMode from './EditMode';
import EntryItemViewMode from './ViewMode';
import { unstable_usePrompt } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';

interface Props {
	entry?: Entry;
	defaultEditMode?: boolean;
	paddingTop?: number;
	paddingBottom?: number;
	/** Entry that's currently being edited through the keyboard shortcuts (onMoveToNextEntry or onMoveToPrevEntry) */
	focusedEntryId?: string | null;
	defaultTags?: string[];
	onEditModeChange?: (isEnabled: boolean) => void;
	/** Triggered through the CMD + Enter shortcut to edit the next entry or create a new one if this entry is the last */
	onMoveToNextEntry?: () => void;
	/** Triggered through the CMD + SHIFT + Enter shortcut to edit the previous entry */
	onMoveToPrevEntry?: () => void;
	onCancel?: () => void;
}

const EntryItem: React.FC<Props> = (props) => {
	const {
		defaultEditMode,
		entry,
		paddingTop,
		paddingBottom,
		focusedEntryId,
		defaultTags,
		onEditModeChange,
		onMoveToNextEntry,
		onMoveToPrevEntry,
		onCancel,
	} = props;
	const { t } = useTranslation();
	const [isHovered, setIsHovered] = useState(false);
	const [isEditModeEnabled, setIsEditModeEnabled] = useState(
		defaultEditMode || focusedEntryId === entry?._id
	);

	useBeforeunload(isEditModeEnabled ? (event) => event.preventDefault() : undefined);

	unstable_usePrompt({
		message: t('unsavedChangesWarning'),
		when: isEditModeEnabled,
	});

	useEffect(() => {
		setIsEditModeEnabled(defaultEditMode || focusedEntryId === entry?._id || false);
	}, [defaultEditMode, focusedEntryId]);

	useEffect(() => {
		setIsHovered(false);
	}, [isEditModeEnabled, entry]);

	useEffect(() => {
		if (entry) {
			onEditModeChange?.(isEditModeEnabled);
		}
	}, [isEditModeEnabled]);

	return (
		<Card
			onDoubleClick={() => setIsEditModeEnabled(true)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			boxShadow={'none'}
			border={isHovered || isEditModeEnabled ? '1px solid #ebebeb' : undefined}
			position='relative'
			borderRadius={0}>
			<CardBody py={isEditModeEnabled ? 4 : 1} pt={paddingTop} pb={paddingBottom}>
				{isEditModeEnabled ? (
					<EntryItemEditMode
						entry={entry}
						defaultTags={defaultTags}
						onCancel={() => {
							setIsEditModeEnabled(false);
							onCancel?.();
						}}
						onMoveToNextEntry={() => {
							setIsHovered(false);
							onMoveToNextEntry?.();
						}}
						onMoveToPrevEntry={() => {
							setIsHovered(false);
							onMoveToPrevEntry?.();
						}}
					/>
				) : (
					<EntryItemViewMode
						entry={entry!}
						isHovered={isHovered}
						onEditClick={() => setIsEditModeEnabled(true)}
					/>
				)}
			</CardBody>
		</Card>
	);
};

export default EntryItem;
