import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import chatGptLogo from '../../images/chat-gpt-log.png';
import { Icon } from '@chakra-ui/react';
import { BsPerson } from 'react-icons/bs';
import { GiSettingsKnobs } from 'react-icons/gi';

type Section = {
	title: string;
	Icon: ReactNode;
	settings: Setting[];
};

export type Setting = {
	name: string;
	type: 'number' | 'words' | 'checkbox';
	path: string;
	hasHelperText?: boolean;
};

export const getSettingsSections = (t: TFunction<string, string>) => {
	const sections: Section[] = [
		{
			title: 'ChatGPT',
			Icon: <img alt='chat-gpt' src={chatGptLogo} style={{ width: 20, height: 20 }} />,
			settings: [
				{
					name: t('maxNoOfMessagesPerConversation'),
					type: 'number',
					path: 'chatGpt.maxNoOfMessagesPerConversation',
				},
				{
					name: t('minAllowWordsToAcceptQuery'),
					type: 'number',
					path: 'aiSearchFilter.minAllowWordsToAcceptQuery',
					hasHelperText: true,
				},
				{
					name: t('minDenyWordsToDenyQuery'),
					type: 'number',
					path: 'aiSearchFilter.minDenyWordsToDenyQuery',
					hasHelperText: true,
				},
				{
					name: t('allowWords'),
					type: 'words',
					path: 'aiSearchFilter.allowWords',
				},
				{
					name: t('denyWords'),
					type: 'words',
					path: 'aiSearchFilter.denyWords',
				},
			],
		},
		{
			title: t('user'),
			Icon: <Icon fontSize='lg' as={BsPerson} />,
			settings: [
				{
					name: t('maxNoOfWorkspacesForUser'),
					type: 'number',
					path: 'user.maxNoOfWorkspacesForUser',
				},
			],
		},
		{
			title: t('application'),
			Icon: <Icon fontSize='lg' as={GiSettingsKnobs} />,
			settings: [
				{
					name: t('showMostRecentEntriesOnHomePage'),
					type: 'checkbox',
					path: 'application.showMostRecentEntriesOnHomePage',
				},
			],
		},
	];

	return sections;
};
