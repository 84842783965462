import { Card, CardBody } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { StoreState, useAppDispatch } from '../../../redux/store';
import { cloneDeep } from 'lodash';
import Inputs from './Inputs';
import { setAiConversationMessages } from '../../../redux/slices/ai-search';
import Message from './Message';

const AiConversation = () => {
	const dispatch = useAppDispatch();
	const aiConversationMessages = useSelector(
		(state: StoreState) => state.aiSearch.aiConversationMessages
	);

	const isAiSearchLoading = useSelector((state: StoreState) => state.aiSearch.isAiSearchLoading);

	const handleMessageRemoveClick = (index: number) => {
		let updatedArray = cloneDeep(aiConversationMessages);
		updatedArray[index] = { ...updatedArray[index], isRemoved: true };

		dispatch(setAiConversationMessages([...updatedArray]));
	};

	if (!aiConversationMessages.length) {
		return null;
	}

	return (
		<Card marginBottom={4}>
			<CardBody>
				{aiConversationMessages.map((message, index) => (
					<Message
						key={index}
						message={message}
						onRemoveClick={() => handleMessageRemoveClick(index)}
					/>
				))}

				{isAiSearchLoading && (
					<Message isLoading message={{ role: 'assistant', content: 'Working on it...' }} />
				)}

				<Inputs />
			</CardBody>
		</Card>
	);
};

export default AiConversation;
