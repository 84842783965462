import { useTranslation } from 'react-i18next';
import {
	Button,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { TFolder } from '../Nav/SidebarContent/Pages/Pages';
import { Page } from '../../redux/slices/pages';

interface Props {
	isOpen: boolean;
	parentFolderPath?: string;
	selectedFolder?: TFolder | null;
	allPages: Page[];
	onSave?: (newFolderName: string) => void;
	onClose: () => void;
}
// TODO: Add the ability to rename a folder, which should change the folderPaths for all associated pages

const FolderModal: React.FC<Props> = ({
	isOpen,
	selectedFolder,
	parentFolderPath,
	allPages,
	onSave,
	onClose,
}) => {
	const [folderName, setFolderName] = useState('');
	const [error, setError] = useState('');
	const { t } = useTranslation();

	const handleSave = () => {
		if (selectedFolder) {
			// TODO: Edit folder and its nested pages
		} else {
			const folderExists = allPages.find(
				(page) => page.folderPath === `${parentFolderPath}/${folderName}`
			);

			if (folderExists) {
				setError(t('folderNameExists') as string);
				return;
			} else {
				setError('');
			}

			onSave?.(folderName);
			onClose();
		}
	};

	useEffect(() => {
		if (isOpen) {
			setError('');
			setFolderName(selectedFolder?.name || '');
		}
	}, [isOpen, selectedFolder]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent pb={4}>
				<ModalHeader>
					{selectedFolder ? t('editFolder') : t('createFolder')}
					<ModalCloseButton />
					<ModalBody></ModalBody>
				</ModalHeader>
				<ModalBody>
					<Text>{t('folderName')}</Text>
					<Input
						mt={2}
						mb={4}
						value={folderName}
						onChange={(e) => setFolderName(e.target.value)}
						onKeyDown={(e) => e.key === 'Enter' && handleSave()}
					/>
					{error && (
						<Text mt={2} color='red.500'>
							{error}
						</Text>
					)}
				</ModalBody>
				<ModalFooter gap={4}>
					<Button onClick={onClose}>{t('close')}</Button>
					<Button isDisabled={!folderName} colorScheme='blue' mr={3} onClick={handleSave}>
						{t('save')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default FolderModal;
