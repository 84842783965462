import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL } from '../../config';
import axios from 'axios';
import { fetchEntries } from './entries';

export type Workspace = {
	_id: string;
	name: string;
};

type WorkspacesSliceState = {
	workspaces: Workspace[];
	selectedWorkspaceId: string | null;
	isWorkspacesLoading: boolean;
};

export const SELECTED_WORKSPACE_ID_LS_KEY = 'selected-workspace-id';

const initialState: WorkspacesSliceState = {
	isWorkspacesLoading: true,
	selectedWorkspaceId: localStorage.getItem(SELECTED_WORKSPACE_ID_LS_KEY) || null,
	workspaces: [],
};

export const getWorkspaces = createAsyncThunk('workspaces/get', async () => {
	const result = await axios.get(`${API_URL}/workspaces`);

	return result.data.workspaces;
});

export const createWorkspace = createAsyncThunk(
	'workspaces/create',
	async (inputValues: Partial<Workspace>) => {
		const result = await axios.post(`${API_URL}/workspaces`, inputValues);
		return result.data;
	}
);

export const updateWorkspace = createAsyncThunk(
	'workspace/update',
	async (payload: { workspaceId: string; inputValues: Partial<Workspace> }) => {
		const result = await axios.put(
			`${API_URL}/workspaces/${payload.workspaceId}`,
			payload.inputValues
		);

		return result.data as Workspace;
	}
);

export const deleteWorkspace = createAsyncThunk('workspace/delete', async (workspaceId: string) => {
	await axios.delete(`${API_URL}/workspaces/${workspaceId}`);

	return workspaceId;
});

export const workspacesSlice = createSlice({
	name: 'workspaces',
	initialState,
	reducers: {
		setSelectedWorkspace: (state, action) => {
			if (action.payload) {
				localStorage.setItem(SELECTED_WORKSPACE_ID_LS_KEY, action.payload);
			} else {
				localStorage.removeItem(SELECTED_WORKSPACE_ID_LS_KEY);
			}
			state.selectedWorkspaceId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(createWorkspace.fulfilled, (state, action) => {
			state.workspaces.push(action.payload);
		});

		builder.addCase(updateWorkspace.fulfilled, (state, action) => {
			const workspaceIndex = state.workspaces.findIndex(
				(workspace) => workspace._id === action.payload._id
			);

			if (workspaceIndex > -1) {
				state.workspaces[workspaceIndex] = action.payload;
			}
		});

		builder.addCase(deleteWorkspace.fulfilled, (state, action) => {
			state.workspaces = state.workspaces.filter((workspace) => workspace._id !== action.payload);
		});

		builder.addCase(getWorkspaces.pending, (state) => {
			state.isWorkspacesLoading = true;
		});

		builder.addCase(getWorkspaces.fulfilled, (state, action) => {
			state.isWorkspacesLoading = false;
			state.workspaces = action.payload;
		});

		/** Reset the selected workspaces to All Organization if user no longer has access to the selected workspace */
		builder.addCase(fetchEntries.rejected, (state, action) => {
			const parsedError = JSON.parse(action.error.message || '{}');

			if (parsedError.status === 403) {
				state.selectedWorkspaceId = null;
				localStorage.removeItem(SELECTED_WORKSPACE_ID_LS_KEY);
			}
		});
	},
});

export const { setSelectedWorkspace } = workspacesSlice.actions;
