import { Box, Divider, useColorModeValue } from '@chakra-ui/react';
import { useLocation } from 'react-router';
import MainSidebar from './MainSidebar';
import SearchSidebar from './SearchSidebar';

const SidebarContent = ({
	isAdminPage,
	onClose,
	...rest
}: {
	isAdminPage?: boolean;
	display?: Record<string, any>;
	onClose: () => void;
}) => {
	const width = useSideBarWidth();
	const location = useLocation();

	return (
		<Box
			transition='3s ease'
			bg={useColorModeValue('white', 'gray.900')}
			borderRight='1px'
			borderRightColor={useColorModeValue('gray.200', 'gray.700')}
			w={width + 'px'}
			pos='fixed'
			h='full'
			{...rest}>
			<MainSidebar onClose={onClose} />

			{location.pathname === '/' && (
				<>
					<Divider width='1' height='100%' orientation='vertical' />
					<SearchSidebar />
				</>
			)}
		</Box>
	);
};

export const SIDE_BAR_WITH_SEARCH_WIDTH = 550;
export const SIDE_BAR_WIDTH = 250;

export const useSideBarWidth = () => {
	const location = useLocation();

	return location.pathname === '/' ? SIDE_BAR_WITH_SEARCH_WIDTH : SIDE_BAR_WIDTH;
};

export default SidebarContent;
